/* React Imports */

import {
  IonCard,
  IonRow,
  IonCol,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSkeletonText,
  IonGrid,
} from "@ionic/react";

/*****************************************************************************
 *
 * Name : DummyProfile
 * Description : Show dummy loading profiles while searching
 * Author : Alex Jacob
 * Date Written : 27/03/2021
 *
 *****************************************************************************/
const DummyProfile: React.FC = () => {
  return (
    <IonGrid fixed={true}>
      <IonRow>
        <IonCol size="12" size-sm="6" size-xl="4">
          <IonCard>
            <div className="tutor-profile-img"></div>
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">
                <IonSkeletonText
                  animated
                  style={{ height: "20px", width: "100%" }}
                />
              </IonCardTitle>
              <IonCardSubtitle class="ion-text-center">
                <IonSkeletonText
                  animated
                  style={{ height: "20px", width: "100%" }}
                />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent class="ion-text-center">
              <IonSkeletonText
                animated
                style={{ height: "50px", width: "100%" }}
              />
              <br />
            </IonCardContent>
            <br />
          </IonCard>
        </IonCol>
        <IonCol size="12" size-sm="6" size-xl="4">
          <IonCard>
            <div className="tutor-profile-img"></div>
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">
                <IonSkeletonText
                  animated
                  style={{ height: "20px", width: "100%" }}
                />
              </IonCardTitle>
              <IonCardSubtitle class="ion-text-center">
                <IonSkeletonText
                  animated
                  style={{ height: "20px", width: "100%" }}
                />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent class="ion-text-center">
              <IonSkeletonText
                animated
                style={{ height: "50px", width: "100%" }}
              />
              <br />
            </IonCardContent>
            <br />
          </IonCard>
        </IonCol>
        <IonCol size="12" size-sm="6" size-xl="4">
          <IonCard>
            <div className="tutor-profile-img"></div>
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">
                <IonSkeletonText
                  animated
                  style={{ height: "20px", width: "100%" }}
                />
              </IonCardTitle>
              <IonCardSubtitle class="ion-text-center">
                <IonSkeletonText
                  animated
                  style={{ height: "20px", width: "100%" }}
                />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent class="ion-text-center">
              <IonSkeletonText
                animated
                style={{ height: "50px", width: "100%" }}
              />
              <br />
            </IonCardContent>
            <br />
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DummyProfile;
