/* React Imports */
import { IonIcon, IonButton, IonCol } from "@ionic/react";
import { cashOutline } from "ionicons/icons";

/*****************************************************************************
 *
 * Name : disabledMessageArea
 * Description : Disable messsage area for INACTIVE subscriptions
 * yet started with any messages.
 * Author : Alex Jacob
 * Date Written : 27/03/2021
 *
 *****************************************************************************/

export const disabledMessageArea = () => {
  return (
    <IonCol size="12" className="ion-text-center">
      <span>
        <IonButton
          color="primary"
          fill="outline"
          slot="end"
          routerLink="/subscribe"
        >
          <IonIcon slot="start" icon={cashOutline} />
          Subscribe
        </IonButton>
      </span>
    </IonCol>
  );
};

/*****************************************************************************
 *
 * Name : getEmptyChatArea
 * Description : Return the empty chat area if user is not subscribed or not
 * yet started with any messages.
 * Author : Alex Jacob
 * Date Written : 27/03/2021
 *
 *****************************************************************************/

export const getEmptyChatArea = (
  id: string,
  role: string,
  chats: any,
  subscription: any
) => {
  let message = "";
  if (id == null) {
    // tutor messages
    if (role === "2") {
      if (chats === null) {
        message = "You have no messages";
      }
    }
    // family messages
    else if (role === "3") {
      //if (subscription == null) {
      //  message = "You need to subscribe to enable this feature";
      //} else {
        message = "Start your conversation";
      //}
    }
  }
  if (message != "") {
    return (
      <div className="message-empty-container ion-hide-md-down">
        <div className="message-empty-image">
          <img src="assets/images/chat-empty.svg" />
        </div>
        <h4>{message}</h4>
      </div>
    );
  } else {
    return <></>;
  }
};
