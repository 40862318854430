import {
  IonChip,
  IonIcon,
  IonLabel,
  IonText,
  IonRow,
  IonButton,
  IonCol,
  IonItem,
} from "@ionic/react";
import {
  tvOutline,
  peopleOutline,
  cashOutline,
  schoolOutline,
  bookOutline,
  createOutline,
  documentOutline,
  earthOutline,
  locationOutline,
  timeOutline,
  ribbonOutline,
} from "ionicons/icons";

/*********************************************************************
 *  Paypal Payment Configuration starts here
 *********************************************************************/

// Values can be set to PROD / SANDBOX

let PAYPALRGN = "PROD";
//let PAYPALRGN = "SANDBOX";

export const EDU1MONPLAN =
  PAYPALRGN === "PROD"
    ? "P-9BA25081778860849MAD3N5Q" // PROD MONTHLY PLAN ID
    : "P-27S53243KY936701KMBNFYOY"; // SANDBOX MONTHLY PLAN ID

export const EDU3MONPLAN =
  PAYPALRGN === "PROD"
    ? "P-40E35609WY757161WMBSIOII" // PROD 3 MONTH PLAN ID
    : "P-39788203D7569094AMBNFW2A"; // SANDBOX 3 MONTH PLAN ID

export const EDU12MONPLAN =
  PAYPALRGN === "PROD"
    ? "P-70P62829BB9476256MBSIPAI" // PROD 12 MONTH PLAN ID
    : "P-71V24709X1345604PMBNFVGQ"; // SANDBOX 12 MONTH PLAN ID

/*********************************************************************
 *  Paypal Payment Configurations ends here
 *********************************************************************/

// get profile image
export const getProfileImage = (path: string) => {
  let baseURL = "https://api.24-7edu.com/";
  let defaultURL = "assets/images/user.png";
  if (typeof path != "undefined") {
    if (path != "") {
      if (path.includes(baseURL)) {
        return path;
      } else {
        return baseURL + path;
      }
    } else {
      return defaultURL;
    }
  } else {
    return defaultURL;
  }
};

// get tutor name
export const getTutorName = (firstName: string, lastName: string) => {
  const first_name = firstName === null ? "" : firstName;
  const last_name = lastName === null ? "" : lastName;
  return first_name + " " + last_name;
};

// Get Tutoring Modes
export const getTutoringModes = (modality: any) => {
  let _modes: any = [];
  //online
  if (modality === "1") {
    _modes.push(
      <IonChip>
        <IonIcon icon={tvOutline} />
        <IonLabel>Online</IonLabel>
      </IonChip>
    );
  }
  //inperson
  if (modality === "2") {
    _modes.push(
      <IonChip>
        <IonIcon icon={peopleOutline} />
        <IonLabel>In Person</IonLabel>
      </IonChip>
    );
  }
  //both online/inperson
  if (modality === "3") {
    _modes.push(
      <IonChip>
        <IonIcon icon={tvOutline} />
        <IonLabel>Online</IonLabel>
      </IonChip>
    );
    _modes.push(
      <IonChip>
        <IonIcon icon={peopleOutline} />
        <IonLabel>In Person</IonLabel>
      </IonChip>
    );
  }
  return _modes;
};

// get tutor bio details
export const getTutorBio = (bio: any, biotype: string) => {
  if (bio === null) {
    return "Tutor has not updated their bio.";
  } else {
    // send a shortened 100 words bio
    if (biotype == "short") {
      return bio.substring(0, 100) + "...";
    }
    // return complete bio
    else {
      return bio;
    }
  }
};

// get tutor rate details
export const getTutorRates = (minrate: any, maxrate: any) => {
  if (minrate === null || maxrate === null) {
    return "Hourly Rates N/A";
  } else {
    return "$" + minrate + " - " + maxrate + " /Hr";
  }
};

// get Tutor Subjects and Level Details
export const getTutorSubjects = (subjects: any) => {
  const subjectCount = subjects.length;

  // If no subjects are updated by the tutor
  if (subjectCount === 0) {
    return (
      <IonText className="text-xs">Tutor has not updated any subjects</IonText>
    );
  }
  // Subjects are available
  else {
    const availableSubjects: any = [];
    subjects.map((subject: any, index: any) => {
      if (subject.level_name === "College") {
        availableSubjects.push(
          <IonCol size="12" size-lg="6">
            <IonItem lines="none">
              <IonLabel className="text-xs">{subject.subject_name}</IonLabel>
              <IonIcon color="primary" icon={schoolOutline} slot="start" />
            </IonItem>
          </IonCol>
        );
      }
      else if (subject.level_name === "High School") {
        availableSubjects.push(
          <IonCol size="12" size-lg="6">
            <IonItem lines="none">
              <IonLabel className="text-xs">{subject.subject_name}</IonLabel>
              <IonIcon color="primary" icon={bookOutline} slot="start" />
            </IonItem>
          </IonCol>
        );
      }
      else if (subject.level_name === "K-8") {
        availableSubjects.push(
          <IonCol size="12" size-lg="6">
            <IonItem lines="none">
              <IonLabel className="text-xs">{subject.subject_name}</IonLabel>
              <IonIcon color="primary" icon={createOutline} slot="start" />
            </IonItem>
          </IonCol>
        );
      }
      else if (subject.level_name === "Test Prep") {
        availableSubjects.push(
          <IonCol size="12" size-lg="6">
            <IonItem lines="none">
              <IonLabel className="text-xs">{subject.subject_name}</IonLabel>
              <IonIcon color="primary" icon={documentOutline} slot="start" />
            </IonItem>
          </IonCol>
        );
      }
      else {
        availableSubjects.push(
          <IonCol size="12" size-lg="6">
            <IonItem lines="none">
              <IonLabel className="text-xs">{subject.subject_name}</IonLabel>
              <IonIcon color="primary" icon={ribbonOutline} slot="start" />
            </IonItem>
          </IonCol>
        );
      }
    });
    return availableSubjects;
  }
};

// get Tutor Locations
export const getTutorLocations = (locations: any, timezones: any) => {
  const locationsCount = locations.length;
  const timezoneCount = timezones.length;
  const availableLocations: any = [];

  if (timezoneCount > 0 || locationsCount > 0) {
    availableLocations.push(
      <IonCol size="12" class="ion-text-left ion-padding">
        <IonText color="primary" className="text-xs">
          <b>Time Zone & Locations</b>
        </IonText>
      </IonCol>
    );
  }

  // Timezones are available
  if (timezoneCount > 0) {
    timezones.map((timezone: any, index: any) => {
      availableLocations.push(
        <IonCol size="12" size-lg="6">
          <IonItem lines="none">
            <IonIcon slot="start" icon={earthOutline} />
            <IonLabel className="text-xs">{timezone.timezone_name}</IonLabel>
          </IonItem>
        </IonCol>
      );
    });
  }
  // Locations are available
  if (locationsCount > 0) {
    locations.map((location: any, index: any) => {
      availableLocations.push(
        <IonCol size="12" size-lg="6">
          <IonItem lines="none">
            <IonIcon slot="start" icon={locationOutline} />
            <IonLabel className="text-xs">
              {location.city_name + ", " + location.state_name}
            </IonLabel>
          </IonItem>
        </IonCol>
      );
    });
  }
  return availableLocations;
};

// get Tutor Availability
export const getTutorAvailability = (schedule: any) => {
  const scheduleCount = schedule.length;

  // If schedule is updated by the tutor
  if (scheduleCount > 0) {
    const availableSchedule: any = [];
    availableSchedule.push(
      <IonCol size="12" class="ion-text-left ion-padding">
        <IonText color="primary" className="text-xs">
          <b>Tutoring Schedule</b>
        </IonText>
      </IonCol>
    );
    schedule.map((entry: any, index: any) => {
      availableSchedule.push(
        <IonCol size="12" size-lg="6">
          <IonItem lines="none">
            <IonIcon slot="start" icon={timeOutline} />
            <IonLabel className="text-xs">
              {entry.day + " - " + entry.time}
            </IonLabel>
          </IonItem>
        </IonCol>
      );
    });
    return availableSchedule;
  }
};

/* General Utility Functions 

export function formatParagraph(text: string) {

  console.log(text);
  // Split text into individual sentences
  const sentences = text.split(". ");
  const sentenceCount = sentences.length;

  const paragraphs = [];
  let counter = 0;
  let tempText = "";

  while (counter < sentenceCount) {
    if (counter % 5 === 0 && counter > 0) {
      tempText = tempText + sentences[counter] + ".";
      paragraphs.push(<p>{tempText}</p>);
      tempText = "";
    } else {
      tempText = tempText + sentences[counter] + ". ";
    }
    counter = counter + 1;
  }
  paragraphs.push(<p>{tempText}</p>);
  return paragraphs;
}

*/

/* Get Monthly Subscription Request */
export const monthlySubRequest = () => {
  const request = {
    plan_id: EDU1MONPLAN,
    application_context: {
      brand_name: "24-7Edu",
      locale: "en-US",
      shipping_preference: "SET_PROVIDED_ADDRESS",
      user_action: "SUBSCRIBE_NOW",
      payment_method: {
        payer_selected: "PAYPAL",
        payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
      },
      return_url:
        "https://learn.24-7edu.com/verification/Acvq_gZYGOxmiIF_duBRV75B6MiraAx3iXIJ4OjjftEyOUJrxvHoqsOwLRaFplBATUOYHxcU8I8dVKfk",
      cancel_url: "https://learn.24-7edu.com",
    },
  };
  return request;
};

/* Get Quaterly Subscription Request */
export const quarterlySubRequest = () => {
  const request = {
    plan_id: EDU3MONPLAN,
    application_context: {
      brand_name: "24-7Edu",
      locale: "en-US",
      shipping_preference: "SET_PROVIDED_ADDRESS",
      user_action: "SUBSCRIBE_NOW",
      payment_method: {
        payer_selected: "PAYPAL",
        payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
      },
      return_url:
        "https://learn.24-7edu.com/verification/Acvq_gZYGOxmiIF_duBRV75B6MiraAx3iXIJ4OjjftEyOUJrxvHoqsOwLRaFplBATUOYHxcU8I8dVKfk",
      cancel_url: "https://learn.24-7edu.com",
    },
  };
  return request;
};

/* Get Annual Subscription Request */
export const annualSubRequest = () => {
  const request = {
    plan_id: EDU12MONPLAN,
    application_context: {
      brand_name: "24-7Edu",
      locale: "en-US",
      shipping_preference: "SET_PROVIDED_ADDRESS",
      user_action: "SUBSCRIBE_NOW",
      payment_method: {
        payer_selected: "PAYPAL",
        payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
      },
      return_url:
        "https://learn.24-7edu.com/verification/Acvq_gZYGOxmiIF_duBRV75B6MiraAx3iXIJ4OjjftEyOUJrxvHoqsOwLRaFplBATUOYHxcU8I8dVKfk",
      cancel_url: "https://learn.24-7edu.com",
    },
  };
  return request;
};

export const requestSubscribe = () => {
  return (
    <IonRow>
      <IonCol size="12" size-xl="3"></IonCol>
      <IonCol size="12" size-xl="6">
        <div className="subscription-inactive">
          <p>
            <b>Not Yet Subscribed ?</b>
          </p>
          <p className="text-xs">
            To message educators, you first need to purchase a subscription
            package.
          </p>
          <span>
            <IonButton
              color="dark"
              fill="outline"
              slot="end"
              routerLink="/subscribe"
            >
              <IonIcon slot="start" icon={cashOutline} />
              Subscribe
            </IonButton>
          </span>
        </div>
      </IonCol>
      <IonCol size="12" size-xl="3"></IonCol>
    </IonRow>
  );
};

/* General Utilities */

export const validateRegistration = (formData: any) => {
  let errorMsg = "";
  if (formData["fname"] === "") {
    errorMsg = "First name can not be blank";
  } else if (formData["fname"].length < 2) {
    errorMsg = "First name can't be this short";
  } else if (formData["lname"] === "") {
    errorMsg = "Last name can not be blank";
  } else if (formData["lname"].length < 2) {
    errorMsg = "Last name can't be this short";
  } else if (formData["email"] === "") {
    errorMsg = "Email can not be blank";
  } else if (formData["email"].length < 10) {
    errorMsg = "Email format is not valid";
  } else if (!validateEmail(formData["email"])) {
    errorMsg = "Email format is not valid";
  } else if (formData["password"] === "") {
    errorMsg = "Password can not be blank";
  } else if (formData["password"].length <= 5) {
    errorMsg = "Password length should be minimum 6 characters";
  } else if (formData["password"] != formData["cfmPassword"]) {
    errorMsg = "Passwords are not matching!";
  } else if (formData["role"] === "") {
    errorMsg = "Choose your purpose of registration";
  } else if (!formData["checked"]) {
    errorMsg = "You must accept the terms and conditions";
  }
  return errorMsg;
};

export const scheduleData = [
  "Monday-Morning",
  "Monday-Afternoon",
  "Monday-Evening",
  "Tuesday-Morning",
  "Tuesday-Afternoon",
  "Tuesday-Evening",
  "Wednesday-Morning",
  "Wednesday-Afternoon",
  "Wednesday-Evening",
  "Thursday-Morning",
  "Thursday-Afternoon",
  "Thursday-Evening",
  "Friday-Morning",
  "Friday-Afternoon",
  "Friday-Evening",
  "Saturday-Morning",
  "Saturday-Afternoon",
  "Saturday-Evening",
  "Sunday-Morning",
  "Sunday-Afternoon",
  "Sunday-Evening",
];

export const wordCount = (str: string) => {
  return str.trim().split(/\s+/).length;
};

export const validateRequest = (profile: any) => {
  let _error = "success";

  if (wordCount(profile.profile_summary.tutor_bio) < 3) {
    _error = "Your bio is missing. Please fill in the bio section to continue.";
    return _error;
  }
  if (wordCount(profile.profile_summary.tutor_bio) > 250) {
    _error =
      "Your bio has more than 250 words.  Please edit your bio to include a maximum of 250 words.";
    return _error;
  }
  if (profile.subjects.length == 0) {
    _error = "Please select at least one subject to continue.";
    return _error;
  }
  if (profile.profile_summary.modality == 0) {
    _error =
      "No modality has been selected. Please select a modality to continue.";
    return _error;
  }
  // in person is selected
  if (
    profile.profile_summary.modality === "2" ||
    profile.profile_summary.modality === "3"
  ) {
    if (profile.locations.length == 0) {
      _error = "State & City should be selected for the in person services.";
      return _error;
    }
  }
  if (profile.schedule.length == 0) {
    _error =
      "Your availability is missing. Please fill in your weekly availability.";
    return _error;
  }
  if (profile.pricing.min_rate < 1 || isNaN(profile.pricing.min_rate)) {
    _error =
      "Your hourly rate is missing. Please provide a numerical hourly rate.";
    return _error;
  }
  if (profile.pricing.max_rate < 1 || isNaN(profile.pricing.max_rate)) {
    _error =
      "The maximum hourly rate is missing. Please provide a numerical maximum hourly rate";
    return _error;
  }
  if (profile.pricing.min_rate > profile.pricing.max_rate) {
    _error =
      "The minimum hourly rate is greater than the maximum hourly rate.  Please change the rates so that the minimum hourly rate is less than the maximum hourly rate.";
    return _error;
  }
  return _error;
};

export const validateEmail = (mail: string) => {
  var mailformat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.match(mailformat)) {
    return true;
  } else {
    return false;
  }
};
