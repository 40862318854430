import * as actions from "./actions";

/* Tutor Search */

export const storeSearchRecords = (data: any) => {
  return {
    type: actions.SEARCH,
    data: data,
  };
};

export const clearSearchRecords = () => {
    return {
      type: actions.CLEARSEARCH,
      data: []
    };
  };

export const storeSearchResults = (data: any) => {
  return function (dispatch: any) {
    dispatch(storeSearchRecords(data));
  };
};

export const clearSearchResults = () => {
    return function (dispatch: any) {
      dispatch(clearSearchRecords());
    };
  };
  