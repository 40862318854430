import * as actions from "./actions";
import API from "../api";

export const storeChatHeads = (data:any) => {
  return {
    type: actions.GETCHATHEADS,
    data: data
  };
};

export const getChatHeads = (data:any) => {
  return function (dispatch: any) {
    API
      .getChatHeads(data)
      .then((result) => {
        dispatch(storeChatHeads(result.data.reponseData));
      })
      .catch((error) => {
      });
  };
};

export const storeSelectedChat = (id:any) => {
    return {
      type: actions.SELECTCHAT,
      data: id
    };
  };
  

