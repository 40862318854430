import * as actions from "../actions/actions";

const initialState = {
    currentChat: '',
    chatHeads:[],
};

const storeChatHeads = (state: any, action: any) => {
  return {
    ...state,
    chatHeads: action.data,
  };
};
const storeCurrentChat = (state: any, action: any) => {
    return {
    ...state,
    currentChat: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.GETCHATHEADS:
      return storeChatHeads(state, action);
      case actions.SELECTCHAT:
      return storeCurrentChat(state, action);
    default:
      return state;
  }
};

export default reducer;
