/* React Imports */
import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonAlert,
  IonIcon,
  IonButton,
  IonModal,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import {
  keyOutline,
  closeCircleOutline,
  cashOutline,
  checkmarkDoneOutline,
  exitOutline,
  helpCircleOutline,
} from "ionicons/icons";

/* Redux store reducers and actions */
import { RootState } from "../store/reducers";
import { cancelSubscription } from "../store/actions";

/* Application APIs */
import { UserContext } from "../App";

/* Utilities */
import { getProfileImage, getTutorName, requestSubscribe } from "../Utilities";

/* Application components */
import FooterContainer from "../components/FooterContainer";
import HeaderContainer from "../components/HeaderContainer";
import ChangePassword from "../components/ChangePassword";

/*****************************************************************************
 *
 * Name : Acccount
 * Description : Account page for Tutors and Family
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/

const Acccount: React.FC = () => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const [supportModal, showSupportModal] = useState(false);
  const [changePasswordModal, showChangePasswordModal] = useState(false);
  const [showCancelAlert, setCancelAlert] = useState(false);
  const userData: any = useSelector<RootState>((state) => state.user.user);
  const tutorData: any = useSelector<RootState>((state) => state.user.tutor);
  const subscription: any = useSelector<RootState>(
    (state) => state.subscription.details
  );
  const handleLogout = () => {
    localStorage.clear();
    user.setIsLoggedIn(false);
    window.location.href="/"
  };

  const handleCancel = () => {
    setCancelAlert(true);
  };

  const handleApprove = () => {
    subscription.links.map((link: any, key: any) => {
      if (link["rel"] === "approve") {
        window.open(subscription.links[key].href);
      }
    });
  };

  const handleSupport = () => {
    showSupportModal(true);
  };

  const handleChangePassword = () => {
    showChangePasswordModal(true);
  };

  const showSubscriptionStatus = () => {
    // subscription is null, means no such subscription with paypal
    if (subscription === null) {
      return requestSubscribe();
    }
    // subscription is available with paypal, status should be validated
    else {
      if (
        subscription.status === "ACTIVE" ||
        subscription.status === "APPROVED" ||
        subscription.status === "APPROVAL_PENDING"
      ) {
        return (
          <IonRow>
            <IonCol size="12" size-xl="3"></IonCol>
            <IonCol size="12" size-xl="6">
              <div className="subscription-active">
                <p>
                  Your Subscription is <b>{subscription.status}</b>
                </p>

                <span>
                  {
                    /* If subscription is pending show approve button */
                    subscription.status === "APPROVAL_PENDING" ? (
                      <>
                        <p>
                          Your last subscription attempt was incomplete.
                          However, you can still approve and complete your
                          subscription.
                        </p>

                        <IonButton
                          color="dark"
                          fill="outline"
                          slot="end"
                          onClick={handleApprove}
                        >
                          <IonIcon slot="start" icon={checkmarkDoneOutline} />
                          Approve
                        </IonButton>
                        <IonButton
                          color="dark"
                          fill="outline"
                          slot="end"
                          routerLink="/subscribe"
                        >
                          <IonIcon slot="start" icon={cashOutline} />
                          New Subscription
                        </IonButton>
                      </>
                    ) : (
                      /* else show cancel button */
                      <>
                        <p className="text-xs">
                          Your last payment{" "}
                          <b>
                            $
                            {
                              subscription.billing_info.last_payment.amount
                                .value
                            }
                          </b>{" "}
                          was on{" "}
                          <b>
                            {subscription.billing_info.last_payment.time.substring(
                              0,
                              10
                            )}
                          </b>
                          <br />
                          Your next payment is due on{" "}
                          <b>
                            {subscription.billing_info.next_billing_time.substring(
                              0,
                              10
                            )}
                          </b>
                        </p>
                        <IonButton
                          color="dark"
                          fill="outline"
                          slot="end"
                          onClick={handleCancel}
                        >
                          <IonIcon slot="start" icon={closeCircleOutline} />
                          Cancel
                        </IonButton>
                      </>
                    )
                  }
                </span>
              </div>
            </IonCol>
            <IonCol size="12" size-xl="3"></IonCol>
          </IonRow>
        );
      }
      // status can be CANCELLED OR EXPIRED
      else {
        return (
          <IonRow>
            <IonCol size="12" size-xl="3"></IonCol>
            <IonCol size="12" size-xl="6">
              <div className="subscription-inactive">
                <p>
                  Your Subscription is <b>{subscription.status}</b>
                </p>
                <p className="text-xs">
                  Your last payment{" "}
                  <b>${subscription.billing_info.last_payment.amount.value}</b>{" "}
                  was on{" "}
                  <b>
                    {subscription.billing_info.last_payment.time.substring(
                      0,
                      10
                    )}
                  </b>
                </p>
                <span>
                  <IonButton
                    color="dark"
                    fill="outline"
                    slot="end"
                    routerLink="/subscribe"
                  >
                    <IonIcon slot="start" icon={cashOutline} />
                    Subscribe
                  </IonButton>
                </span>
              </div>
            </IonCol>
            <IonCol size="12" size-xl="3"></IonCol>
          </IonRow>
        );
      }
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer backButton={true} role={userData.role} title="" />
        <div className="avatar-img">
          <img
            src={
              userData.role === "2"
                ? getProfileImage(tutorData.profile_summary[0].image)
                : getProfileImage(userData.profile_image)
            }
          />
        </div>

        <IonGrid fixed={true}>
          <div className="ion-text-center">
            <h1 className="ion-no-padding ion-no-margin">
              {getTutorName(userData.first_name, userData.last_name)}
            </h1>
            <IonText className="text-xs">{userData.email_id}</IonText>
            <br />
            <br />
            <IonButton fill="outline" slot="end" onClick={handleSupport}>
              <IonIcon slot="start" icon={helpCircleOutline} />
              Support
            </IonButton>
            <IonButton fill="outline" slot="end" onClick={handleChangePassword}>
              <IonIcon slot="start" icon={keyOutline} />
              Change Password
            </IonButton>
            <IonButton
              fill="outline"
              slot="end"
              onClick={handleLogout}
              routerLink="/"
            >
              <IonIcon slot="start" icon={exitOutline} />
              Logout
            </IonButton>
            <br />
            <IonGrid>
              {/*userData.role === "3" ? showSubscriptionStatus() : ""*/}
            </IonGrid>
          </div>
          <br />
        </IonGrid>
        <IonModal
          isOpen={supportModal}
          showBackdrop={true}
          onDidDismiss={() => showSupportModal(false)}
        >
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol>
                <div className="tutor-profile-img">
                  <img src="assets/images/support.png" />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-text-center">
                <h1>Need Support?</h1>
                <IonText className="text-xs">
                  If you have any questions or need further information as to
                  the Site or Platform provided by 24-7edu.com, or need to
                  notify 24-7edu.com as to any matters relating to the Site or
                  Platform please contact 24-7edu.com at
                </IonText>
                <br />
                <br />
                <br />
                <IonText color="primary">
                  2400 Pearl Street Austin
                  <br />
                  Texas 78705
                  <br />
                  <br />
                  <br />
                  Phone: +1-512-472-6666 <br />
                  Email: support@24-7edu.com
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonModal>
        <IonAlert
          isOpen={showCancelAlert}
          mode="ios"
          onDidDismiss={() => setCancelAlert(false)}
          header={"Cancel"}
          subHeader={""}
          message={
            "This will cancel your subscription completely and will not be able to enjoy the benefits from now!"
          }
          buttons={[
            {
              text: "Close",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Cancel",
              handler: () => {
                dispatch(
                  cancelSubscription(subscription.id, {
                    reason: "Cancelled from 24-7edu MyAccount",
                  })
                );
              },
            },
          ]}
        />
      </IonContent>
      <IonModal
        isOpen={changePasswordModal}
        showBackdrop={true}
        cssClass="login-modal"
        onDidDismiss={() => showChangePasswordModal(false)}
      >
        <ChangePassword reset={true} />
      </IonModal>
      <FooterContainer />
    </IonPage>
  );
};

export default Acccount;
