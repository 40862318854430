import axios from "axios";

/* 24-7Edu API Configurations */
const eduAPI = axios.create({
  baseURL: "https://api.24-7edu.com/",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

eduAPI.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

/**********************************************************************************
 * Paypal PRODUCTION Authentication API Configurations
 * Author: Alex Jacob
 * Date Written : 31/03/2021
 * *********************************************************************************/
const username =
  "AcBsq1fSa3XMLYvKeyqPU3981IeuPPu9a3E6svRhHvEeNMjWeHrnYbWzgk0MgndBwW1UhPQ_CLKn7pDG";
const password =
  "EBG3ivSVfvn346ln2efArDFc1SqZzcC848nlkDJxtaXQtL0h_KMWvPizUIIY-iopIQlYlVWUMFYMaNw6";

const basicAuth = "Basic " + btoa(username + ":" + password);

const paypalAuthAPI = axios.create({
  baseURL: "https://api-m.paypal.com/v1/",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: basicAuth,
  },
});

/* Paypal Subscription API Configurations */
const paypalAPI = axios.create({
  baseURL: "https://api-m.paypal.com/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

/**********************************************************************************
* Paypal SANDBOX Authentication API Configurations
* Author: Alex Jacob
* Date Written : 31/03/2021
* *********************************************************************************
const username =
  "Acvq_gZYGOxmiIF_duBRV75B6MiraAx3iXIJ4OjjftEyOUJrxvHoqsOwLRaFplBATUOYHxcU8I8dVKfk";
const password =
  "EIvgshwoxJ__-gB5Vqn2rj-YLP4ZiGGb4HOh2LJyYGu0xAKyMzRV7pq3loBLlQmLVZxHqyNXKbw26PWL";

const paypalAuthAPI = axios.create({
  baseURL: "https://api-m.sandbox.paypal.com/v1/",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: basicAuth,
  },
});
const paypalAPI = axios.create({
  baseURL: "https://api-m.sandbox.paypal.com/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});
*/

paypalAPI.interceptors.request.use(function (config) {
  const token = localStorage.getItem("paypaltoken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default {
  userLogin: (data: any) =>
    eduAPI({
      method: "POST",
      url: "auth/login",
      data: data,
    }),

  userSignup: (data: any) =>
    eduAPI({
      method: "POST",
      url: "auth/sign-up",
      data: data,
    }),
  verifyAccount: () =>
    eduAPI({
      method: "GET",
      url: "auth/account-verify",
    }),
  changePassword: (data: any) =>
    eduAPI({
      method: "POST",
      url: "auth/change-password",
      data: data,
    }),
  resetPassword: (data: any) =>
    eduAPI({
      method: "POST",
      url: "auth/forgot-password",
      data: data,
    }),
  updatePayment: (data: any) =>
    eduAPI({
      method: "POST",
      url: "auth/payment-update",
      data: data,
    }),
  getServices: () =>
    eduAPI({
      method: "GET",
      url: "view-service-list",
    }),

  getStates: () =>
    eduAPI({
      method: "GET",
      url: "get-states-list-search",
    }),

  getCities: (id: string) =>
    eduAPI({
      method: "GET",
      url: "get-city-by-state-id/" + id,
    }),

  getTimezones: () =>
    eduAPI({
      method: "GET",
      url: "get-tmezone-list",
    }),

  getLevels: (id: any) =>
    eduAPI({
      method: "GET",
      url: "view-level-list/" + id,
    }),

  getSubjects: (data: any) =>
    eduAPI({
      method: "POST",
      url: "view-subject-list",
      data: data,
    }),
  getAvailableSubjects: (data: any) =>
    eduAPI({
      method: "POST",
      url: "view-subject-listV1",
      data: data,
    }),
  tutorSearch: (data: any) =>
    eduAPI({
      method: "POST",
      url: "v2/tutor-search",
      data: data,
    }),

  /* Messaging API */

  sendChat: (data: any) =>
    eduAPI({
      method: "POST",
      url: "chat/send-chat",
      data: data,
    }),

  getChatHeads: (data: any) =>
    eduAPI({
      method: "POST",
      url: "chat/get-my-chat-head",
      data: data,
    }),

  getChatDetails: (data: any) =>
    eduAPI({
      method: "POST",
      url: "chat/get-chat-conversation",
      data: data,
    }),

  /*************************************************************
   * Tutor Service APIs
   * Author: Alex Jacob
   * Date Written : 28/03/2021
   * **********************************************************/

  getTutorProfile: () =>
    eduAPI({
      method: "GET",
      url: "/v2/get-tutor-profile",
    }),

  updateTutorProfile: (data: any) =>
    eduAPI({
      method: "PATCH",
      url: "/v2/tutor/profile",
      data: data,
    }),

  uploadImage: (data: any) =>
    eduAPI({
      method: "POST",
      url: "/tutor-profile-image",
      data: data,
    }),

  /*************************************************************
   * Paypal APIs
   * Author: Alex Jacob
   * Date Written : 28/03/2021
   * **********************************************************/

  /* Paypal Authentication API */
  paypalAuth: () =>
    paypalAuthAPI({
      method: "POST",
      url: "oauth2/token?grant_type=client_credentials",
    }),

  /* Create Subscription */
  createSubscription: (data: any) =>
    paypalAPI({
      method: "POST",
      url: "billing/subscriptions",
      data: data,
    }),

  /* Get Subscription */
  getSubscription: (id: string) =>
    paypalAPI({
      method: "GET",
      url: "billing/subscriptions/" + id,
    }),

  /* Cancel Subscription */
  cancelSubscription: (id: string, data: any) =>
    paypalAPI({
      method: "POST",
      url: "billing/subscriptions/" + id + "/cancel",
      data: data,
    }),
};
