/* React Imports */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers";
import {
  IonIcon,
  IonButtons,
  IonList,
  IonBackButton,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonTitle,
  IonAvatar,
  IonLabel,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";

/* Redux store actions */
import { storeSelectedUser } from "../store/actions";

/*****************************************************************************
 *
 * Name : ChatHeader
 * Description : Create the chat header according to user interactions with
 * message page for tutors and family
 * Author : Alex Jacob
 * Date Written : 27/03/2021
 *
 *****************************************************************************/
const ChatHeader: React.FC = () => {
  const chatUser: any = useSelector<RootState>(
    (state) => state.user.selectedUser
  );
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(storeSelectedUser([]));
  };

  return (
    <IonGrid className="ion-no-padding message-header">
      <IonRow class="ion-no-padding">
        <IonCol size="12" size-md="4" class="ion-no-padding">
          {chatUser.id == null ? (
            <IonList className="ion-no-padding">
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonBackButton text="" defaultHref="/search" />
                  <IonTitle className="text-s">Inbox</IonTitle>
                </IonButtons>
              </IonToolbar>
            </IonList>
          ) : (
            <IonList className="ion-no-padding ion-hide-md-down">
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonBackButton text="" defaultHref="/search" />
                  <IonTitle className="text-s">Inbox</IonTitle>
                </IonButtons>
              </IonToolbar>
            </IonList>
          )}
        </IonCol>
        <IonCol size="12" size-md="8" class="ion-no-padding">
          {chatUser.id != null ? (
            <IonList className="ion-no-padding">
              <IonItem color="primary" lines="none">
                <IonIcon
                  slot="start"
                  onClick={handleBack}
                  class="ion-hide-md-up ion-no-margin ion-margin-end"
                  icon={arrowBackOutline}
                />
                <IonAvatar slot="start">
                  <img src={chatUser.image} />
                </IonAvatar>
                <IonLabel>
                  <h2>{chatUser.name}</h2>
                </IonLabel>
              </IonItem>
            </IonList>
          ) : (
            <IonList className="ion-no-padding ion-hide-md-down">
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonTitle className="text-s">
                    Your Recent Conversations
                  </IonTitle>
                </IonButtons>
              </IonToolbar>
            </IonList>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ChatHeader;
