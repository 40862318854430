/* React Import */
import {
  IonCard,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";

/* Interface for Props*/
interface TutorProfileProps {
  tutor_id: string;
  tutor_name: string;
  tutor_rates: string;
  tutor_bio: any;
  tutor_img: string;
}

/*****************************************************************************
 *
 * Name : TutorProfile
 * Description : Tutor profile on search result
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/

const TutorProfile: React.FC<TutorProfileProps> = ({
  tutor_id,
  tutor_name,
  tutor_rates,
  tutor_bio,
  tutor_img,
}) => {
  const tutorDetails = "/tutor/" + tutor_id;

  return (
    <IonCard>
      <div className="tutor-profile-img">
        <img src={tutor_img} />
      </div>
      <IonCardHeader>
        <IonCardTitle color="primary" class="ion-text-center">
          {tutor_name}
        </IonCardTitle>
        <IonCardSubtitle class="ion-text-center">{tutor_rates}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent class="ion-text-center">
        {tutor_bio}
        <br />
        <br />
        <IonButton fill="outline" slot="end" routerLink={tutorDetails}>
          View Profile
        </IonButton>
      </IonCardContent>
      <br />
    </IonCard>
  );
};

export default TutorProfile;
