/* React Imports */
import {
  IonLoading,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/* Application APIs */
import API from "../store/api";

/* Redux store actions */
import {
  getServices,
  getStates,
  getLevels,
  storeUser,
  getChatHeads,
  getSubscription,
  getTimezones,
} from "../store/actions";

/* Utilities */
import { validateEmail } from "../Utilities";

/* User context from application */
import { UserContext } from "../App";

/*****************************************************************************
 *
 * Name : LoginModal
 * Description : Login Modal Window for Tutors and Family
 * Author : Alex Jacob
 * Date Written : 24/03/2021
 *
 *****************************************************************************/
const LoginModal: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [mode, setMode] = useState<string>("login");
  const [loginMessage, setLoginMessage] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const history = useHistory();
  const authData = {
    email_id: email,
    password: password,
  };

  const loginClick = () => {
    setShowLoading(true);
    API.userLogin(authData)
      .then((result) => {
        setShowLoading(false);

        // store the 247 user token in local storage and save user details in store
        localStorage.setItem("token", result.data.reponseData.accessToken);
        dispatch(storeUser(result.data.reponseData));

        // set the login status and role in user context
        user.setIsLoggedIn(true);

        // if logged user is tutor
        if (result.data.reponseData.role === "2") {
          history.push("/dashboard");
          dispatch(getChatHeads([]));
          dispatch(getServices());
          //dispatch(getLevels());
          dispatch(getStates());
          dispatch(getTimezones());
        }
        // logged user is not tutor
        else {
          history.push("/search");
          // get all the required dropdown box values for the search interface
          dispatch(getServices());

          dispatch(getStates());
          //dispatch(getLevels());
          dispatch(getChatHeads([]));

          let paymentStatus = result.data.reponseData.paymentStatus;
          let subscribe_id = result.data.reponseData.paypal_sub_id;

          /******************************************************************************************
           * Payapl Authentication and get the subscription status of the user
           *******************************************************************************************/
          /*API.paypalAuth()
            .then((result) => {
              localStorage.setItem("paypaltoken", result.data.access_token);
              if (paymentStatus == "2" && subscribe_id != "") {
                dispatch(getSubscription(subscribe_id));
              }
            })
            .catch((error) => {});
          */
        }
      })
      .catch((error) => {
        if (error.response) {
          setLoginMessage(error.response.data.message)
        } else {
          setLoginMessage("Login attempt failed!");
        }
        user.setIsLoggedIn(false);
        setShowLoading(false);
      });
  };

  const resetClick = () => {
    if (validateEmail(authData.email_id)) {
      setShowLoading(true);
      API.resetPassword({ email_id: authData.email_id })
        .then((result) => {
          setShowLoading(false);
          setLoginMessage("success");
        })
        .catch((error) => {
          setLoginMessage("We don't have an account with this email address");
          setShowLoading(false);
        });
    } else {
      setLoginMessage("Email address is not valid!");
    }
  };

  const handleReset = () => {
    setMode("reset");
    setLoginMessage("");
  };
  const handleLogin = () => {
    setMode("login");
    setLoginMessage("");
  };
  return (
    <>
      <IonGrid fixed={true} className="ion-no-padding ion-no-margin">
        <IonRow>
          <IonCol class="ion-hide-lg-down">
            <img src="assets/images/247-login-banner.jpeg" />
          </IonCol>
          <IonCol className="ion-padding">
            <IonList>
              <br />
              <IonText class="ion-text-center">
                {mode === "login" ? (
                  <h1 className="ion-no-padding ion-no-margin">Login</h1>
                ) : (
                  <h1 className="ion-no-padding ion-no-margin">
                    Forgot Password
                  </h1>
                )}
              </IonText>
              <br />
              <br />
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>

                <IonInput
                  value={email}
                  onIonChange={(e: any) => setEmail(e.target.value)}
                  required
                />
              </IonItem>
              {mode === "login" ? (
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    value={password}
                    onIonChange={(e: any) => setPassword(e.target.value)}
                    required
                    type="password"
                  />
                </IonItem>
              ) : null}
              {loginMessage === "success" ? (
                <>
                  <br />
                  <div className="alert-success">
                    <p className="ion-text-center text-s">
                      We have sent you an email to {email} Kindly follow the
                      instruction in mail.
                    </p>
                  </div>
                </>
              ) : (
                <IonText color="danger">
                  <p className="ion-text-center text-s">{loginMessage}</p>
                </IonText>
              )}
              <br />
              {mode === "login" ? (
                <IonButton fill="outline" expand="block" onClick={loginClick}>
                  <IonText color="primary">Login</IonText>
                </IonButton>
              ) : (
                <IonButton fill="outline" expand="block" onClick={resetClick}>
                  <IonText color="primary">Reset</IonText>
                </IonButton>
              )}

              {mode === "login" ? (
                <IonText onClick={handleReset}>
                  <p className="login-reset-link">Forgot Password</p>
                </IonText>
              ) : (
                <IonText onClick={handleLogin}>
                  <p className="login-reset-link">Login</p>
                </IonText>
              )}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
    </>
  );
};
export default LoginModal;
