import * as actions from "../actions/actions";

const initialState = {
  user: [],
  tutor: [],
  action: "",
  selectedUser: [],
};

const storeUserDetails = (state: any, action: any) => {
  return {
    ...state,
    user: action.data,
  };
};

const storeSelectedTutorDetails = (state: any, action: any) => {
  return {
    ...state,
    selectedUser: action.data,
  };
};

const updatePayment = (state: any, action: any) => {
  return {
    ...state,
    user: {
      ...state.user,
      paypal_sub_id: action.data.subscribe_id,
      paymentStatus: action.data.payment_status,
    },
  };
};

const storeTutorProfile = (state: any, action: any) => {
  return {
    ...state,
    tutor: action.data,
  };
};

const storeUserAction = (state: any, action: any) => {
  return {
    ...state,
    action: action.data,
  };
};

const reducer = (state = initialState, action: any) => {

  switch (action.type) {
    case actions.SIGNIN:
      return storeUserDetails(state, action);
    case actions.SELECTEDTUTOR:
      return storeSelectedTutorDetails(state, action);
    case actions.STOREACTION:
      return storeUserAction(state, action);
    case actions.UPDATEPAYMENT:
      return updatePayment(state, action);
    case actions.STORE_TUTOR_PROFILE:
      return storeTutorProfile(state, action);
    default:
      return state;
  }
};

export default reducer;
