/* React Imports */
import React from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonCard,
  IonIcon,
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { logoPaypal } from "ionicons/icons";

/* Redux store actions */
import { createSubscription } from "../store/actions";

/* Utilities */
import {
  monthlySubRequest,
  quarterlySubRequest,
  annualSubRequest,
} from "../Utilities";

/* Application components */
import FooterContainer from "../components/FooterContainer";
import HeaderContainer from "../components/HeaderContainer";

/*****************************************************************************
 *
 * Name : Subscription
 * Description : Subscription page for families
 * Author : Alex Jacob
 * Date Written : 24/03/2021
 *
 *****************************************************************************/

const Subscription: React.FC = () => {
  const dispatch = useDispatch();
  const handleMonthlyPlan = () => {
    dispatch(createSubscription(monthlySubRequest()));
  };

  const handleQuarterlyPlan = () => {
    dispatch(createSubscription(quarterlySubRequest()));
  };
  const handleAnnualPlan = () => {
    dispatch(createSubscription(annualSubRequest()));
  };
  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer backButton={true} role="3" title="" />
        <div className="avatar-img">
          <img src="assets/images/paypal.png" />
        </div>
        <IonGrid fixed={true} class="ion-text-center">
          <IonRow>
            <IonCol>
              <h1>Subscribe Your Plan</h1>
              <IonText className="text-xs">
                To message educators, you first need to purchase a subscription
                package.
              </IonText>
            </IonCol>
          </IonRow>
          <br />
          <br />
          <IonRow>
            <IonCol size="12" size-md="4">
              <IonCard className="ion-padding subscriptions">
                <IonCardHeader>
                  <IonCardTitle color="primary">Monthly Plan</IonCardTitle>
                  <IonCardSubtitle>1 Month @ $29.99/month</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    color="secondary"
                    fill="solid"
                    slot="end"
                    onClick={handleMonthlyPlan}
                  >
                    <IonIcon slot="start" icon={logoPaypal} />
                    Subscribe
                  </IonButton>
                  <br />
                  <IonText color="dark" className="text-xs">
                    Total Amount: $29.99
                  </IonText>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" size-md="4">
              <IonCard className="ion-padding subscriptions">
                <IonCardHeader>
                  <IonCardTitle color="primary">Quarterly Plan</IonCardTitle>
                  <IonCardSubtitle>3 Months @ $19.99/month</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonButton
                    color="secondary"
                    fill="solid"
                    slot="end"
                    onClick={handleQuarterlyPlan}
                  >
                    <IonIcon slot="start" icon={logoPaypal} />
                    Subscribe
                  </IonButton>
                  <br />
                  <IonText color="dark" className="text-xs">
                    Total Amount: $59.97
                  </IonText>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" size-md="4">
              <IonCard className="ion-padding subscriptions">
                <IonCardHeader>
                  <IonCardTitle color="primary">Annual Plan</IonCardTitle>
                  <IonCardSubtitle>12 Months @ $9.99/month</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonButton
                    color="secondary"
                    fill="solid"
                    slot="end"
                    onClick={handleAnnualPlan}
                  >
                    <IonIcon slot="start" icon={logoPaypal} />
                    Subscribe
                  </IonButton>
                  <br />
                  <IonText color="dark" className="text-xs">
                    Total Amount: $119.88
                  </IonText>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <br/><br/>
        </IonGrid>
      </IonContent>
      <FooterContainer />
    </IonPage>
  );
};

export default Subscription;
