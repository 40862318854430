/* React Import */
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonChip,
  IonIcon,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonSlide,
  IonItem,
  IonSlides,
} from "@ionic/react";
import {
  mailOpenOutline,
  pricetagOutline,
  checkmarkOutline,
  buildOutline,
  alertCircleOutline,
} from "ionicons/icons";

/* Redux store reducers and actions */
import { RootState } from "../store/reducers";
import { storeAction, storeTutorProfile } from "../store/actions";

/* Utilities */
import {
  getTutorName,
  getProfileImage,
  getTutorBio,
  getTutoringModes,
  getTutorRates,
  getTutorSubjects,
  getTutorLocations,
  getTutorAvailability,
} from "../Utilities";

/* Application APIs */
import API from "../store/api";

/* Application components */
import HeaderContainer from "../components/HeaderContainer";
import ChatIcon from "../components/ChatIcon";

/*****************************************************************************
 *
 * Name : Dashboard
 * Description : Dashboard for tutors
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  // slider states
  const [value, setValue] = useState("0");
  const slider = useRef<HTMLIonSlidesElement>(null);

  // local states
  const [tutor, setTutor] = useState({
    id: "",
    name: "",
    bio: "",
    image: "",
    modality: "",
    pricing: "",
    subjects: [],
    locations: [],
    schedule: [],
  });

  // get states from Redux store
  const userState: any = useSelector<RootState>((state) => state.user);
  const tutorState: any = useSelector<RootState>((state) => state.user.tutor);

  // get tutor profile details
  useEffect(() => {
    //API.getTutorProfile(userState.user.user_id)
    API.getTutorProfile()
      .then((result) => {
        dispatch(storeTutorProfile(result.data.reponseData));
        setTutor(getTutorDetails(result.data.reponseData));
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (userState.action === "SAVE_PROFILE") {
      setTutor(getTutorDetails(tutorState));
      dispatch(storeAction(""));
    }
  }, [userState.action]);

  const getTutorDetails = (tutorState: any) => {

    let tutor = {
      id: tutorState.tutor_id,
      name: getTutorName(
        tutorState.profile_summary[0].first_name,
        tutorState.profile_summary[0].last_name
      ),
      bio: getTutorBio(tutorState.profile_summary[0].tutor_bio, ""),
      image: getProfileImage(tutorState.profile_summary[0].image),
      modality: getTutoringModes(tutorState.profile_summary[0].modality),
      pricing: getTutorRates(
        tutorState.pricing[0].min_hourly_rate,
        tutorState.pricing[0].max_hourly_rate
      ),
      subjects: getTutorSubjects(tutorState.subjects),
      locations: getTutorLocations(tutorState.locations, tutorState.timezone),
      schedule: getTutorAvailability(tutorState.schedule_info),
    };
    return tutor;
    
  };

  // function to handle the segment change
  const handleSegmentChange = (e: any) => {
    setValue(e.detail.value);
    slider.current!.slideTo(e.detail.value);
  };

  // function to handle the slider changes
  const handleSlideChange = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    setValue("" + index);
  };

  // slider options
  const slideOpts = {
    initialSlide: 0,
    speed: 100,
    autoHeight: false,
    loop: false,
    pagination: {
      el: null,
    },
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer backButton={false} role="2" title="" />
        <div className="tutor-details-profile-img">
          <img src={tutor.image} />
        </div>
        <IonGrid fixed={true}>
          <div className="ion-text-center">
            <h1 className="ion-text-center">
              <IonText color="dark">{tutor.name}</IonText>
            </h1>
            <IonButton fill="outline" slot="end" routerLink="/profile">
              <IonIcon slot="start" icon={buildOutline} />
              Edit Profile
            </IonButton>
            <IonButton fill="outline" slot="end" routerLink="/message">
              <IonIcon slot="start" icon={mailOpenOutline} />
              Messages
            </IonButton>
            <br />
            <br />
            {userState.user.paymentStatus === "1" ? (
              <IonChip>
                <IonIcon icon={checkmarkOutline} />
                <IonLabel>Verified</IonLabel>
              </IonChip>
            ) : (
              <IonChip>
                <IonIcon icon={alertCircleOutline} />
                <IonLabel>Unverified</IonLabel>
              </IonChip>
            )}
            {tutor.modality}
            <IonChip>
              <IonIcon icon={pricetagOutline} />
              <IonLabel>{tutor.pricing}</IonLabel>
            </IonChip>
          </div>
          <br />
          <br />
          <IonSegment
            mode="md"
            value={value}
            onIonChange={(e) => handleSegmentChange(e)}
          >
            <IonSegmentButton value="0">
              <IonLabel>Bio</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="1">
              <IonLabel>Subjects</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2">
              <IonLabel>Availability</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonSlides
            pager={true}
            options={slideOpts}
            onIonSlideDidChange={(e) => handleSlideChange(e)}
            ref={slider}
          >
            <IonSlide>
              <IonGrid fixed={true}>
                <br />
                <br />
                <IonRow>
                  <IonCol class="ion-text-left" className="text-xs">
                    <IonText color="dark">{tutor.bio}</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonSlide>

            <IonSlide>
              <IonGrid fixed={true}>
                <br />
                <br />
                <IonRow>{tutor.subjects}</IonRow>
              </IonGrid>
            </IonSlide>
            <IonSlide>
              <IonGrid fixed={true}>
                <br />
                <br />
                <IonRow>{tutor.locations}</IonRow>
                <IonRow>{tutor.schedule}</IonRow>
              </IonGrid>
            </IonSlide>
          </IonSlides>
        </IonGrid>

        <br />
        <br />
      </IonContent>
      <ChatIcon status="unread" />
    </IonPage>
  );
};

export default Dashboard;
