import { optionsOutline } from "ionicons/icons";
import {
  IonIcon,
  IonGrid,
  IonRow,
  IonProgressBar,
  IonItem,
  IonLabel,
  IonCol,
  IonText,
} from "@ionic/react";

/* Interface Props */
interface SearchStatusProps {
  status: string;
}
/*****************************************************************************
 *
 * Name : SearchStatusBar
 * Description : Search status bar to show the status of the search like
 * records, error messages and warnings.
 * Author : Alex Jacob
 * Date Written : 24/03/2021
 *
 *****************************************************************************/
const SearchStatusBar: React.FC<SearchStatusProps> = ({ status }) => {
  return (
    <div className="search-status">
      <IonGrid fixed={true}>
        <IonRow className="ion-no-padding">
          <IonCol className="ion-no-padding">
            <IonItem lines="none" className="search-status-area">
              <IonLabel>{status}</IonLabel>
              {/*
              <IonText color="primary" className="text-s">OPTIONS&nbsp;&nbsp;</IonText>
              <IonIcon icon={optionsOutline}></IonIcon>*/}
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
      </div>
  );
};

export default SearchStatusBar;
