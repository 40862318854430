/* React Imports*/
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonAvatar, IonList, IonItem, IonLabel } from "@ionic/react";

/* Redux Store reducers and actions */
import { RootState } from "../store/reducers";
import { storeSelectedUser, storeSelectedChat } from "../store/actions";

/* Utilities */
import { getProfileImage } from "../Utilities";

/*****************************************************************************
 *
 * Name : ChatHeads
 * Description : Create the chat heads for the conversations and update that
 * after every messages.
 * Author : Alex Jacob
 * Date Written : 27/03/2021
 *
 *****************************************************************************/

const ChatHeads: React.FC = () => {
  const dispatch = useDispatch();
  const chatHeads: any = useSelector<RootState>(
    (state) => state.chat.chatHeads
  );
  const userid: any = useSelector<RootState>(
    (state) => state.user.user.user_id
  );

  const handleChat = (id: string, name: string, image: string) => {
    const chatUser = {
      id: id,
      name: name,
      image: image,
    };
    dispatch(storeSelectedUser(chatUser));
    dispatch(storeSelectedChat(id));
  };

  const chatHeadList: any = [];

  if (chatHeads != null) {
    chatHeads.map((chatItem: any, index: any) => {
      if (chatItem != null) {
        if (chatItem.senders_id === userid) {
          chatHeadList.push(
            <IonItem
              lines="full"
              onClick={() =>
                handleChat(
                  chatItem.receivers_id,
                  chatItem.recevers_name,
                  getProfileImage(chatItem.recevers_image)
                )
              }
            >
              <IonAvatar slot="start">
                <img src={getProfileImage(chatItem.recevers_image)} />
              </IonAvatar>
              <IonLabel>
                <h2>{chatItem.recevers_name}</h2>
                <p className="text-xs">
                  {chatItem.message.length > 30
                    ? chatItem.message.substring(0, 30) + "..."
                    : chatItem.message}
                </p>
              </IonLabel>
            </IonItem>
          );
        } else {
          chatHeadList.push(
            <IonItem
              lines="full"
              onClick={() =>
                handleChat(
                  chatItem.senders_id,
                  chatItem.senders_name,
                  getProfileImage(chatItem.senders_image)
                )
              }
            >
              <IonAvatar slot="start">
                <img src={getProfileImage(chatItem.senders_image)} />
              </IonAvatar>
              <IonLabel>
                <h2>{chatItem.senders_name}</h2>
                <p className="text-xs">
                  {chatItem.message.length > 30
                    ? chatItem.message.substring(0, 30) + "..."
                    : chatItem.message}
                </p>
              </IonLabel>
            </IonItem>
          );
        }
      }
    });
  }

  return <IonList>{chatHeadList}</IonList>;
};

export default ChatHeads;
