import * as actions from "./actions";
import API from "../api";
import { updatePayment } from "./user";


export const storeSubscription = (data: any) => {
  return {
    type: actions.SUBSCRIBE,
    data: data,
  };
};

export const createSubscription = (data:any) => {
  return function (dispatch: any) {

    API
      .createSubscription(data)
      .then((result) => {
        window.open(result.data.links[0].href);
        dispatch(storeSubscription(result.data));
        localStorage.setItem('subscribe_id',result.data.id);
        const paymentData ={
          subscribe_id : result.data.id,
          payment_status : "2",
        }
        dispatch(updatePayment(paymentData));
      })
      .catch((error) => {
        dispatch(storeSubscription(null));
      });
  };
};

export const storeSubscriptionDetails = (data: any) => {
  return {
    type: actions.GETSUBSCRIPTION,
    data: data,
  };
};


export const getSubscription = (id:string) => {
  return function (dispatch: any) {
    API
      .getSubscription(id)
      .then((result) => {
        dispatch(storeSubscriptionDetails(result.data));
      })
      .catch((error) => {
        dispatch(storeSubscriptionDetails(null));
      });
  };
};

export const clearSubscriptionDetails = (data: any) => {
  return {
    type: actions.CANCELSUBSCRIPTION,
    data: data,
  };
};


export const cancelSubscription = (id:string, data:any) => {
  return function (dispatch: any) {
    API
      .cancelSubscription(id,data)
      .then((result) => {
        dispatch(clearSubscriptionDetails(null));
      })
      .catch((error) => {
      });
  };
};