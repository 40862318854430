/* React Import */
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonTextarea,
  IonIcon,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import { paperPlaneOutline } from "ionicons/icons";

/* Redux store and actions */
import { RootState } from "../store/reducers";
import { getChatHeads } from "../store/actions";

/* Application APIs */
import api from "../store/api";

/* Application components */
import { disabledMessageArea, getEmptyChatArea } from "../components/Chat";
import ChatHeads from "../components/ChatHeads";
import ChatHeader from "../components/ChatHeader";

/*****************************************************************************
 *
 * Name : Message
 * Description : Messaging for Tutor and Students and it's core logic
 * Author : Alex Jacob
 * Date Written : 26/03/2021
 *
 *****************************************************************************/

const Message: React.FC = () => {
  const [chatMessage, setChatMessage] = useState<string>("");
  const [chatHead, setChatHead] = useState(<ChatHeads />);
  const [chatHistory, setChatHistory] = useState([]);
  const [lastUserId, setlastUserId] = useState<string>("");
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const dispatch = useDispatch();

  const chatUser: any = useSelector<RootState>(
    (state) => state.user.selectedUser
  );
  const chatHeads: any = useSelector<RootState>(
    (state) => state.chat.chatHeads
  );
  const user: any = useSelector<RootState>((state) => state.user.user);

  const subscription: any = useSelector<RootState>(
    (state) => state.subscription.details
  );

  let tempChatHistory: any = [];

  const chatData = {
    receiver_id: chatUser.id,
    message: chatMessage,
  };

  const chatConversationData = {
    receiver_id: chatUser.id,
  };

  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom(500);
  };

  const handleSend = () => {
    if (chatMessage != "") {
      tempChatHistory = [];
      tempChatHistory.push(chatHistory);
      tempChatHistory.push(<div className="senderChat">{chatMessage}</div>);
      setChatHistory(tempChatHistory);
      setChatMessage("");
      api
        .sendChat(chatData)
        .then((result) => {
          getChatConversations();
          dispatch(getChatHeads([]));
        })
        .catch((error) => {
          console.log("message sent failed");
        });
    }
  };

  const getChatConversations = () => {
    api
      .getChatDetails(chatConversationData)
      .then((result) => {
        tempChatHistory = [];
        let chats = result.data.reponseData.records;
        let curDate = "";
        let prevDate = "";

        chats.reverse().map((chat: any, index: any) => {
          curDate = chat.created_at.substring(0, 10);

          //if dates have chaned during the conversation
          if (curDate != prevDate) {
            prevDate = curDate;

            tempChatHistory.push(
              <IonItemDivider mode="md" className="message-divider">
                <p>{curDate}</p>
              </IonItemDivider>
            );
          }

          if (user.user_id === chat.senders_id) {
            tempChatHistory.push(
              <div className="senderChat">{chat.message}</div>
            );
          } else {
            tempChatHistory.push(
              <div className="receiverChat">{chat.message}</div>
            );
          }
        });
        setChatHistory(tempChatHistory);
        scrollToBottom();
      })
      .catch((error) => {});
  };

  const showConversation = () => {
    //chat history available
    if (chatHistory.length > 0) {
      return chatHistory;
    }
    //chat details not available
    else {
      return getEmptyChatArea(chatUser.id, user.role, chatHeads, subscription);
    }
  };

  const getChatHead = () => {
    let message = "";
    if (chatUser.id != null) {
      return <span className="ion-hide-md-down">{chatHead}</span>;
    } else {
      // tutor
      if (user.role === "2") {
        message = "You have no messages";
      }
      // family landed in inbox
      else {
        if (subscription === null) {
          message = "You need to subscribe to enable this feature";
        } else {
          message = "Start your conversation";
        }
      }
      return (
        <>
          <span>{chatHead}</span>{" "}
          {chatHeads != null ? (
            <></>
          ) : (
            <div className="message-empty-container ion-hide-md-up">
              <div className="message-empty-image">
                <img src="assets/images/chat-empty.svg" />
              </div>
              <p>{message}</p>
            </div>
          )}
        </>
      );
    }
  };

  if (lastUserId != chatUser.id) {
    setChatHistory([]);
    setlastUserId(chatUser.id);
    getChatConversations();
  }

  const getChatFeature = () => {
    // subscription is ACTIVE so enable the chat feature or role should be a tutor
    // DISABLED SUBSCRIPTION FOR NOW
    //if (
    //  (subscription != null && subscription.status === "ACTIVE") ||
    //  user.role === "2"
    //) 
    //{
      if (chatUser.id != null) {
        return (
          <>
            <IonCol size="9" size-xl="7">
              <IonTextarea
                rows={1}
                className="message-text-area-input"
                placeholder="Type your message here"
                value={chatMessage}
                onIonChange={(e: any) => setChatMessage(e.target.value)}
              ></IonTextarea>
            </IonCol>
            <IonCol size="3" size-xl="1">
              <IonButton
                className="message-send-but"
                expand="full"
                fill="solid"
                onClick={handleSend}
              >
                <IonIcon
                  class="ion-hide-md-up"
                  slot="start"
                  icon={paperPlaneOutline}
                />
                <IonText class="ion-hide-md-down">SEND</IonText>
              </IonButton>
            </IonCol>
          </>
        );
      } else return <></>;
    //}
    // subscription is not ACTIVE, disable the chat feature
    //else {
    //  return disabledMessageArea();
    //}
  };

  return (
    <>
      <IonPage>
        <ChatHeader />
        <IonContent
          className="message-content-area"
          ref={contentRef}
          scrollEvents={true}
          scrollY={true}
        >
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol
                size="12"
                size-md="4"
                class="ion-no-padding"
                className="message-left-pane"
              >
                {getChatHead()}
              </IonCol>
              <IonCol
                size="12"
                size-md="8"
                class="ion-padding"
                className="message-right-pane "
              >
                {showConversation()}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>

        <div className="message-box">
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol size="12" size-md="4"></IonCol>
              {getChatFeature()}
            </IonRow>
          </IonGrid>
        </div>
      </IonPage>
    </>
  );
};

export default Message;
