import * as actions from "../actions/actions";

const initialState = {
  count: 0,
  results: [],
};

const storeResults = (state: any, action: any) => {
  return {
    ...state,
    count: action.data.count,
    results: action.data.records,
  };
};
const clearResults = (state: any, action: any) => {
  return {
    ...state,
    count: 0,
    results: [],
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.SEARCH:
      return storeResults(state, action);
    case actions.CLEARSEARCH:
      return clearResults(state, action);
    default:
      return state;
  }
};

export default reducer;
