import * as actions from "./actions";
import API from "../api";

/* States */
export const storeStateList= (data:any) => {
  return {
    type: actions.STATES,
    data: data
  };
};

export const getStates = () => {
  return function (dispatch: any) {
    API
      .getStates()
      .then((result) => {
        dispatch(storeStateList(result.data.reponseData));
      })
      .catch((error) => {
        console.log("getStates Failed");
      });
  };
};



/* Cities */
export const storeCityList= (data:any) => {
  return {
    type: actions.CITIES,
    data: data
  };
};

export const getCities = (id:string) => {
    return function (dispatch: any) {
      API
        .getCities(id)
        .then((result) => {
          dispatch(storeCityList(result.data.reponseData));
        })
        .catch((error) => {
          console.log("getCities Failed");
        });
    };
  };

/* Timezones */

export const storeTimezones= (data:any) => {
  return {
    type: actions.TIMEZONES,
    data: data
  };
};
  export const getTimezones= () => {
    return function (dispatch: any) {
      API
        .getTimezones()
        .then((result) => {
          dispatch(storeTimezones(result.data.reponseData));
        })
        .catch((error) => {
          console.log("getTimezones Failed");
        });
    };
  };