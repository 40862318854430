/* React Imports */
import {
  IonContent,
  IonPage,
  IonModal,
  IonText,
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";

/* Application APIs */
import API from "../store/api";

/*****************************************************************************
 *
 * Name : Account Activation
 * Description : Account Activation based on email verification
 * Author : Alex Jacob
 * Date Written : 24/07/2021
 *
 *****************************************************************************/

const Activation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState(true);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (id != null && id != "") {
      localStorage.setItem("token", id);
      API.verifyAccount()
        .then((result) => {
          setStatus("success");
          //setShowModal(true);
        })
        .catch((error) => {
          setStatus("failed");
          //setShowModal(true);
        });
    } else {
      window.location.replace("/");
    }
  }, []);

  const getActivationDetails = (status: string) => {
    if(status === "")
    {
        return (
        <> 
        <br/>   
        <h5>Validating your email address</h5>
        <IonSpinner name="lines" />
        </>
        )
    }
    if (status === "success") {
      return (
        <>
          <IonText color="success">
            <h1>
              <IonIcon icon={checkmarkCircleOutline} />
              <br />
              Your account is active
            </h1>
          </IonText>
          <br />
          <br />
          <span className="text-s">
            Your email <IonText color="primary">alexjacob90@gmail.com</IonText>{" "}
            has been verified and your account is active now. You can continue
            to login page.
          </span>
          <br />
          <br />
          <IonButton
            fill="solid"
            shape="round"
            className="no-shadow"
            color="primary"
            type="submit"
            routerLink="/"
            onClick={() => setShowModal(false)}
          >
            <IonText>Continue</IonText>
          </IonButton>
        </>
      );
    }
    if (status === "failed") {
      return (
        <>
          <IonText color="danger">
            <h1>
              <IonIcon icon={closeCircleOutline} />
              <br />
              Something went wrong!
            </h1>
          </IonText>
          <br />
          <br />
          <span className="text-s">
            Your link is expired or invalid. Please try to login again to
            receive the new activation link in your email.
          </span>
          <br />
          <br />
          <IonButton
            fill="solid"
            shape="round"
            className="no-shadow"
            color="primary"
            type="submit"
            routerLink="/"
            onClick={() => setShowModal(false)}
          >
            <IonText>Continue</IonText>
          </IonButton>
        </>
      );
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonModal
          isOpen={showModal}
          cssClass="signup-modal"
          onDidDismiss={() => setShowModal(false)}
        >
          <div className="content">
            {getActivationDetails(status)}
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Activation;
