/* React Imports */
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import {
  IonButtons,
  IonButton,
  IonBackButton,
  IonToolbar,
  IonHeader,
  IonTitle,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";

/* Interface for Props */
interface HeaderProps {
  backButton: boolean;
  role:string;
  title: string;
}

/*****************************************************************************
 *
 * Name : FooterContainer
 * Description : Application footer
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/
const HeaderContainer: React.FC<HeaderProps> = ({ backButton, role, title }) => {

  const getBackButton = () => {
    if (backButton === true) {
      return (
        <IonButtons slot="secondary">
          <IonBackButton icon={arrowBackOutline} text="" defaultHref="/" />
        </IonButtons>
      );
    }
  };

  const getNavMenu = () => {
    if (role === "2") {
      return (
        <>
          <IonButton color="light" slot="end" routerLink="/dashboard">
            <span className="text-xs">Dashboard</span>
          </IonButton>
          <IonButton color="light" slot="end" routerLink="/account">
            <span className="text-xs">My Account</span>
          </IonButton>
        </>
      );
    }
    if (role === "3") {
      return (
        <>
          <IonButton color="light" slot="end" routerLink="/search">
            <span className="text-xs">Dashboard</span>
          </IonButton>
          <IonButton color="light" slot="end" routerLink="/account">
            <span className="text-xs">My Account</span>
          </IonButton>
        </>
      );
    }
    return (
      <IonButton color="light" slot="end" routerLink="/home">
        <span className="text-xs">Home</span>
      </IonButton>
    );
  };

  return (
    <IonHeader mode="ios" className="ion-no-border">
      <IonToolbar color="primary">
        {getBackButton()}
        <IonTitle className="text-xs ion-hide-sm-down">{title}</IonTitle>
        <IonButtons slot="primary">{getNavMenu()}</IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderContainer;
