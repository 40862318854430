/* React Imports */
import { IonButton, IonContent, IonPage, IonModal } from "@ionic/react";
import React, { useState } from "react";

/* Application components */
import LoginModal from "../components/LoginModal";
import Registration from "../components/Registration";
import IntroSection from "../components/IntroSection";
import FeaturesSection from "../components/FeaturesSection";

/*****************************************************************************
 *
 * Name : Home
 * Description : Home page for everyone!
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/

const Home: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const loginModal = () => {
    setShowModal(true);
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="header ion-text-right">
          <IonButton
            fill="outline"
            color="light"
            size="small"
            onClick={loginModal}
          >
            Login
          </IonButton>
        </div>
        <IntroSection />
        <FeaturesSection/>
        <Registration />

        <IonModal
          isOpen={showModal}
          showBackdrop={true}
          cssClass="login-modal"
          onDidDismiss={() => setShowModal(false)}
        >
          <LoginModal />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Home;
