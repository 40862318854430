import * as actions from "../actions/actions";

const initialState = {
  states: [],
  cities: [],
};

const storeStateList = (state: any, action: any) => {
  return {
    ...state,
    states: action.data,
  };
};

const storeCityList = (state: any, action: any) => {
  return {
    ...state,
    cities: action.data,
  };
};

const storeTimezones = (state: any, action: any) => {
  return {
    ...state,
    timezones: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.STATES:
      return storeStateList(state, action);
    case actions.CITIES:
      return storeCityList(state, action);
    case actions.TIMEZONES:
      return storeTimezones(state, action);
    default:
      return state;
  }
};

export default reducer;
