/* React Imports */
import {
    IonGrid,
    IonRow,
    IonCol,
  } from "@ionic/react";

  /*****************************************************************************
 *
 * Name : FeaturesSection
 * Description : Features sction on home page
 * Author : Alex Jacob
 * Date Written : 28/03/2021
 *
 *****************************************************************************/
  const FeaturesSection: React.FC = () => {
    return (
      <div className="features-section">
        <IonGrid fixed={true} class="ion-padding-horizontal">
           <h1>SELECT THE TYPE OF SERVICE YOU NEED</h1><br/><br/>
          <IonRow>
            <IonCol size="12" size-lg="4">
                <h4>HOMESCHOOLING</h4>
                <p className="text-xs">Enhance your child's learning experience by discovering an educator who can homeschool based on your preferences.</p><br/>
                <h4>ACADEMIC REINFORCEMENT/TUTORING</h4>
                <p className="text-xs">Schedule a one-on-one session with someone that meets your preferences for budget, style and location.</p><br/>
                <h4>SPECIAL NEEDS TUTORING</h4>
                <p className="text-xs">Find someone with knowledge on best practices for those with different learning abilities.</p>
            </IonCol>
            <IonCol size="12" size-lg="4" className="ion-hide-lg-down">
                <img className="feature-section-img" src="https://tutor.24-7edu.com/assets/images/Group%2019.png"/>
            </IonCol>
            <IonCol size="12" size-lg="4">
                <h4>HOMEWORK ASSISTANCE</h4>
                <p className="text-xs">Ask questions, get feedback and dive into homework problems with our homework assistance option.</p><br/>
                <h4>ONLINE ACADEMIC REINFORCEMENT/TUTORING</h4>
                <p className="text-xs">Learn from the comfort of your own home with our online tutoring option!</p><br/>
                <h4>IN PERSON ACADEMIC REINFORCEMENT/TUTORING</h4>
                <p className="text-xs">Have an instructor meet at the location of your choice.</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  };
  export default FeaturesSection;
  