/* React Import */
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonChip,
  IonInput,
  IonIcon,
  IonButton,
  IonLabel,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLoading,
  IonTextarea,
  IonList,
  IonSelect,
  IonModal,
  IonSelectOption,
} from "@ionic/react";
import {
  saveOutline,
  closeCircleOutline,
  addCircleOutline,
  arrowBackOutline,
  cameraOutline,
} from "ionicons/icons";

/* Redux store reducers and actions */
import { RootState } from "../store/reducers";
import {
  storeTutorProfile,
  getLevels,
  getSubjects,
  getCities,
  storeAction,
} from "../store/actions";

/* Utilities */
import {
  getTutorName,
  getProfileImage,
  getTutorBio,
  validateRequest,
  scheduleData,
  wordCount,
} from "../Utilities";

/* Application APIs */
import API from "../store/api";

/* Application components */
import HeaderContainer from "../components/HeaderContainer";
import ChatIcon from "../components/ChatIcon";

/*****************************************************************************
 *
 * Name : Profile
 * Description : Profile for tutors
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/

const Profile: React.FC = () => {
  // get states from Redux store
  const tutorState: any = useSelector<RootState>((state) => state.user.tutor);
  const servicesState: any = useSelector<RootState>((state) => state.services);
  const locationState: any = useSelector<RootState>((state) => state.location);
  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [locations, setLocations] = useState<any[]>([
    { state_id: "", state_name: "", city_id: "", city_name: "" },
  ]);

  const [service, setService] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [level, setLevel] = useState("");
  //const [levelOptions, setLevelOptions] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjects, setSubjects] = useState<any[]>([
    { service_id: "", level_id: "", subject_id: "", subject_name: "" },
  ]);

  const [bio, setBio] = useState("");
  const [profImg, setProfImg] = useState("");
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);
  const [mode, setMode] = useState("");
  const [timezones, setTimezones] = useState<string[]>([]);
  const [schedule, setSchedule] = useState<string[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const [showImageUpload, setShowImageUpload] = useState(false);

  const handleCancelEdit = () => {
    setMode(getExistingMode());
    setBio(getExtistingBio());
    setProfImg(getExistingProfileImage());
    setTimezones(getExistingTimezones());
    setSchedule(getExistingSchedule());
    setMinRate(getMinRate());
    setMaxRate(getMaxRate());
    setLocations(getExistingLocations());
    setSubjects(getExistingSubjects());
    //setLevelOptions(getLevelOptions());
    setServiceOptions(getServiceOptions());
    setState("");
    setCity("");
    setLevel("");
    setSubject("");
    setError("");
  };

  useEffect(() => {
    setMode(getExistingMode());
    setBio(getExtistingBio());
    setProfImg(getExistingProfileImage());
    setTimezones(getExistingTimezones());
    setSchedule(getExistingSchedule());
    setMinRate(getMinRate());
    setMaxRate(getMaxRate());
    setLocations(getExistingLocations());
    setSubjects(getExistingSubjects());
    //setLevelOptions(getLevelOptions());
    setServiceOptions(getServiceOptions());
  }, []);

  useEffect(() => {
    if (state != "") {
      dispatch(getCities(state.split("-")[0]));
    }
    setCity("");
    setShowLoading(true);
  }, [state]);

  useEffect(() => {
    if (service != "") {
      dispatch(getLevels(service));
    }
    setLevel("");
    setSubject("");
    setShowLoading(true);
  }, [service]);

  useEffect(() => {
    if (level != "") {
      dispatch(getSubjects({ levels: [level] }));
    }
    setSubject("");
    setShowLoading(true);
  }, [level]);

  /**************************************************************************
   *  BIO & PROFILE PIC SECTION
   **************************************************************************/
  const getExtistingBio = () => {
    return getTutorBio(tutorState.profile_summary[0].tutor_bio, "");
  };

  const getExistingProfileImage = () => {
    return getProfileImage(tutorState.profile_summary[0].image);
  };

  const getUpdatedProfileImage = (path: string) => {
    return getProfileImage(path);
  };
  /**************************************************************************
   *  MODES SECTION
   **************************************************************************/
  const getExistingMode = () => {
    if (tutorState.profile_summary[0] != null) {
      return tutorState.profile_summary[0].modality;
    } else {
      return "";
    }
  };

  /**************************************************************************
   *  SUBJECTS SECTION
   **************************************************************************/

  const selectInterface = {
    cssClass: "select-options",
  };

  const getExistingSubjects = () => {
    let _subs: any = [];
    tutorState.subjects.map((sub: any, index: any) => {
      _subs.push({
        service_id: sub.service_id,
        level_id: sub.level_id,
        subject_id: sub.subject_id,
        subject_name: sub.subject_name,
      });
    });
    return _subs;
  };

  const getServiceDropdown = () => {
    return (
      <IonItem lines="none">
        <IonSelect
          interfaceOptions={selectInterface}
          value={service}
          cancelText="Cancel"
          okText="Update"
          onIonChange={(e) => setService(e.detail.value)}
        >
          <IonSelectOption value="">Select Service</IonSelectOption>
          {serviceOptions}
        </IonSelect>
      </IonItem>
    );
  };

  const getServiceOptions = () => {
    let _serviceOps: any = [];
    if (servicesState) {
      servicesState.services.map((service: any, index: any) =>
        _serviceOps.push(
          <IonSelectOption key={index} value={service.svc_id}>
            {service.svc_name}
          </IonSelectOption>
        )
      );
      return _serviceOps;
    }
  };

  const getLevelDropdown = () => {
    return (
      <IonItem lines="none">
        <IonSelect
          interfaceOptions={selectInterface}
          value={level}
          cancelText="Cancel"
          okText="Update"
          onIonChange={(e) => setLevel(e.detail.value)}
        >
          <IonSelectOption value="">Select Level</IonSelectOption>
          {service != ""
            ? servicesState.levels.map((level: any, index: any) => (
                <IonSelectOption
                  key={index}
                  value={level.level_id + ";" + level.level_name}
                >
                  {level.level_name}
                </IonSelectOption>
              ))
            : ""}
        </IonSelect>
      </IonItem>
    );
  };



  const getSubjectDropdown = () => {
    return (
      <IonItem lines="none">
        <IonSelect
          interfaceOptions={selectInterface}
          value={subject}
          cancelText="Cancel"
          okText="Update"
          onIonChange={(e) => setSubject(e.detail.value)}
        >
          <IonSelectOption value="">Select Subjects</IonSelectOption>
          {level != ""
            ? servicesState.subjects.map((sub: any, index: any) => (
                <IonSelectOption
                  key={index}
                  value={sub.sub_id + ";" + sub.sub_name}
                >
                  {sub.sub_name}
                </IonSelectOption>
              ))
            : ""}
        </IonSelect>
      </IonItem>
    );
  };

  const showSubjects = () => {
    let _subs: any = [];
    subjects.map((sub: any, i: any) => {
      if (sub.level_id != "") {
        _subs.push(
          <IonChip key={i}>
            <IonIcon
              icon={closeCircleOutline}
              onClick={() => handleSubjectRemove(i)}
            />
            <IonLabel>{sub.subject_name}</IonLabel>
          </IonChip>
        );
      }
    });
    return _subs;
  };

  const getEditedSubjects = () => {
    let _subs: any = [];
    if (subjects.length != 0) {
      subjects.map((sub: any, index: any) => {
        _subs.push({ service_id:sub.service_id, level_id: sub.level_id, subject_id: sub.subject_id });
      });
    }
    return _subs;
  };

  const handleAddSubject = () => {
    let _found = false;
    if (service != "" && level != "" && subject != "") {
      const _sub = {
        service_id: service,
        level_id: level.split(";")[0],
        subject_id: subject.split(";")[0],
        subject_name: subject.split(";")[1],
      };
      subjects.map((sub: any, index: any) => {
        if (JSON.stringify(_sub) === JSON.stringify(sub)) {
          _found = true;
        }
      });
      if (!_found) {
        const _sublist = [...subjects, _sub];
        setSubjects(_sublist);
      }
    }
  };
  const handleSubjectRemove = (index: any) => {
    const _subs = [...subjects];
    _subs.splice(index, 1);
    setSubjects(_subs);
  };

  /**************************************************************************
   *  TimeZone SECTION
   **************************************************************************/

  const getExistingTimezones = () => {
    let selectedTimezones: any = [];
    tutorState.timezone.map((timezone: any, index: any) => {
      selectedTimezones.push(timezone.timezone_master_id);
    });
    return selectedTimezones;
  };
  const getEditedTimezones = () => {
    let editedTimezones: any = [];
    timezones.map((tz: any, index: any) => {
      editedTimezones.push({ timezone_id: tz });
    });
    return editedTimezones;
  };
  /**************************************************************************
   *  Schedule SECTION
   **************************************************************************/

  const getExistingSchedule = () => {
    let selectedSchedule: any = [];
    tutorState.schedule_info.map((schedule: any, index: any) => {
      selectedSchedule.push(schedule.day + "-" + schedule.time);
    });
    return selectedSchedule;
  };

  const getEditedSchedule = () => {
    let editedSchedule: any = [];
    schedule.map((sch: any, index: any) => {
      editedSchedule.push({ day: sch.split("-")[0], time: sch.split("-")[1] });
    });
    return editedSchedule;
  };

  /**************************************************************************
   *  RATES SECTION
   **************************************************************************/
  const getMinRate = () => {
    return parseInt(tutorState.pricing[0].min_hourly_rate!, 10);
  };

  const getMaxRate = () => {
    return parseInt(tutorState.pricing[0].max_hourly_rate!, 10);
  };

  /**************************************************************************
   *  LOCATIONS
   **************************************************************************/

  const getExistingLocations = () => {
    let _locs: any = [];
    tutorState.locations.map((location: any, index: any) => {
      _locs.push({
        state_id: location.state_id,
        state_name: location.state_name,
        city_id: location.city_id,
        city_name: location.city_name,
      });
    });
    return _locs;
  };

  const getStateOptions = () => {
    let optionList: any = [];
    locationState.states.map((state: any, index: any) => {
      optionList.push(
        <IonSelectOption
          key={index}
          value={state.state_id + "-" + state.state_name}
        >
          {state.state_name}
        </IonSelectOption>
      );
    });
    return optionList;
  };

  const getCityOptions = () => {
    let optionList: any = [];
    if (state != "") {
      locationState.cities.map((city: any, index: any) => {
        optionList.push(
          <IonSelectOption
            key={index}
            value={city.city_id + "-" + city.city_name}
          >
            {city.city_name}
          </IonSelectOption>
        );
      });
    }
    return optionList;
  };

  const showLocations = () => {
    let locs: any = [];
    locations.map((loc: any, i: any) => {
      if (loc.state_id != "") {
        locs.push(
          <IonChip key={i}>
            <IonIcon
              icon={closeCircleOutline}
              onClick={() => handleRemoveLocation(i)}
            />
            <IonLabel>{loc.city_name}</IonLabel>
          </IonChip>
        );
      }
    });
    return locs;
  };

  const getEditedLocations = () => {
    let _locs: any = [];
    if (locations.length != 0) {
      locations.map((loc: any, index: any) => {
        _locs.push({ state_id: loc.state_id, city_id: loc.city_id });
      });
    }
    return _locs;
  };
  const handleAddLocation = (index: any) => {
    let found = false;
    if (state != "" && city != "") {
      const location = {
        state_id: state.split("-")[0],
        state_name: state.split("-")[1],
        city_id: city.split("-")[0],
        city_name: city.split("-")[1],
      };
      locations.map((loc: any, index: any) => {
        if (JSON.stringify(loc) === JSON.stringify(location)) {
          found = true;
        }
      });
      if (!found) {
        const locs = [...locations, location];
        setLocations(locs);
      }
    }
  };

  const handleRemoveLocation = (index: any) => {
    const list = [...locations];
    list.splice(index, 1);
    setLocations(list);
  };

  /**************************************************************************
   *  SAVE PROFILE
   **************************************************************************/

  const handleSaveProfile = () => {
    let _error: any = "";

    let _profile = {
      profile_summary: {
        tutor_bio: bio,
        modality: mode,
      },
      subjects: getEditedSubjects(),
      locations: getEditedLocations(),
      schedule: getEditedSchedule(),
      timezone: getEditedTimezones(),
      pricing: {
        min_rate: minRate,
        max_rate: maxRate,
      },
    };

    _error = validateRequest(_profile);
    
    if (_error === "success") {
      setShowLoading(true);
      API.updateTutorProfile(_profile)
        .then((result) => {
          //API.getTutorProfile(tutorState.profile_summary[0].tutor_id)
          API.getTutorProfile()
            .then((result) => {
              dispatch(storeTutorProfile(result.data.reponseData));
              setShowLoading(false);
              dispatch(storeAction("SAVE_PROFILE"));
              setError("updated");
            })
            .catch((error) => {
              setError("Oops! we couldn't update your profile");
            });
        })
        .catch((error) => {
          setError("Oops! we couldn't update your profile");
        });
    } else {
      setError(_error);
    }
    
  };

  const showFormErrors = () => {
    if (error === "") {
      return <></>;
    } else {
      if (error == "updated") {
        return (
          <IonRow>
            <div className="alert-success">
              Your profile has been successfully updated.
            </div>
          </IonRow>
        );
      } else {
        return (
          <IonRow>
            <div className="alert-error">{error}</div>
          </IonRow>
        );
      }
    }
  };

  /**************************************************************************
   *  EDIT Photo
   **************************************************************************/

  const uploadPhoto = () => {
    const files = fileRef.current?.files;
    if (files != null) {
      let file = files[0];
      if (typeof file != "undefined") {
        let formData = new FormData();
        formData.append("tutor_id", tutorState.tutor_id);
        formData.append("TutorProfileImage", file);
        setShowLoading(true);
        API.uploadImage(formData)
          .then((result) => {
            setShowImageUpload(false);
            setProfImg(
              getUpdatedProfileImage(
                result.data.reponseData.TutorProfileImagePath
              )
            );
            API.getTutorProfile()
              .then((result) => {
                dispatch(storeTutorProfile(result.data.reponseData));
                setShowLoading(false);
                dispatch(storeAction("SAVE_PROFILE"));
                setError("updated");
              })
              .catch((error) => {
                setError("Oops! we couldn't update your profile");
              });
          })
          .catch((error) => {
            setError("Oops! we couldn't upload your image");
          });
      }
    }
  };

  /**********************************************************************************************************
   *
   * PROFILE : RETURN AREA
   *
   *********************************************************************************************************/

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer backButton={false} role="2" title="" />

        <div
          className="tutor-details-profile-img"
          onClick={() => setShowImageUpload(true)}
        >
          <img src={profImg} />
          <div className="tutor-profile-img-edit">
            <IonIcon color="light" icon={cameraOutline} />
          </div>
        </div>
        <h1 className="ion-text-center">
          <IonText color="dark">
            {getTutorName(
              tutorState.profile_summary[0].first_name,
              tutorState.profile_summary[0].last_name
            )}
          </IonText>
        </h1>

        <IonGrid fixed={true}>
          <IonRow className="tutor-input-container">
            <IonCol class="ion-text-left">
              <IonText className="text-s" color="primary">
                Write a few sentences (fewer than 250 words), describing your
                background and experience.
              </IonText>
              <br />
              <br />
              <div className="tutor-input-wrapper">
                <IonTextarea
                  rows={10}
                  className="message-text-area-input"
                  placeholder="Write your bio in 250 words"
                  value={bio}
                  onIonChange={(e: any) => setBio(e.target.value)}
                ></IonTextarea>
                <IonText className="text-xs" color="medium">
                  Word Count {wordCount(bio)}
                </IonText>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="tutor-input-container">
            <IonCol>
              <IonText className="text-s" color="primary">
                Select levels and subjects you can help with. After you select
                the level, corresponding subjects will appear. Add subjects to
                your profile by clicking on "Add Subject".
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{showSubjects()}</IonCol>
          </IonRow>
          <IonRow className="tutor-input-wrapper">
            <IonCol>{getServiceDropdown()}</IonCol>
            <IonCol>{getLevelDropdown()}</IonCol>
            <IonCol>{getSubjectDropdown()}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <IonButton fill="outline" onClick={handleAddSubject}>
                Add Subject
                <IonIcon slot="start" icon={addCircleOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="tutor-input-container">
            <IonCol size="12" size-lg="6">
              <IonText className="text-s" color="primary">
                Are you able to help students online, in person, or both?
              </IonText>
              <br />
              <br />
              <IonList className="tutor-input-wrapper">
                <IonItem>
                  <IonSelect
                    value={mode}
                    cancelText="Cancel"
                    okText="Update"
                    onIonChange={(e) => setMode(e.detail.value)}
                  >
                    <IonSelectOption value="0">Select Modality</IonSelectOption>
                    <IonSelectOption value="1">Online</IonSelectOption>
                    <IonSelectOption value="2">In Person</IonSelectOption>
                    <IonSelectOption value="3">
                      Online & In Person
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
            <IonCol size="12" size-lg="6">
              <IonText className="text-s" color="primary">
                What time zone do you prefer?
              </IonText>
              <br />
              <br />
              <IonList className="tutor-input-wrapper">
                <IonItem>
                  <IonLabel>Time Zones ({timezones.length})</IonLabel>
                  <IonSelect
                    interfaceOptions={selectInterface}
                    multiple={true}
                    value={timezones}
                    cancelText="Cancel"
                    okText="Update"
                    onIonChange={(e) => setTimezones(e.detail.value)}
                  >
                    {locationState.timezones.map((tz: any, index: any) => (
                      <IonSelectOption
                        key={index}
                        value={tz.timezone_master_id}
                      >
                        {tz.timezone_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow className="tutor-input-container">
            <IonCol size="12">
              <IonText className="text-s" color="primary">
                List your weekly availability to give students an idea of your
                general schedule. Students will message you individually to
                schedule appointments.
              </IonText>
              <br />
              <br />
              <IonList className="tutor-input-wrapper">
                <IonItem>
                  <IonLabel>Schedule({schedule.length})</IonLabel>
                  <IonSelect
                    interfaceOptions={selectInterface}
                    multiple={true}
                    value={schedule}
                    cancelText="Cancel"
                    okText="Update"
                    onIonChange={(e) => setSchedule(e.detail.value)}
                  >
                    {scheduleData.map((schedule: any, index: any) => (
                      <IonSelectOption key={index} value={schedule}>
                        {schedule}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow className="tutor-input-container">
            <IonCol size="12">
              <IonText className="text-s" color="primary">
                Location: If you can help students in person, please select the
                state and city from the drop down boxes below.
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{showLocations()}</IonCol>
          </IonRow>
          <IonRow className="tutor-input-container">
            <IonCol size="6" className="tutor-input-wrapper">
              <IonItem lines="none">
                <IonSelect
                  value={state}
                  placeholder="Select One"
                  onIonChange={(e) => setState(e.detail.value)}
                >
                  <IonSelectOption value="">Select State</IonSelectOption>
                  {getStateOptions()}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size="6" className="tutor-input-wrapper">
              <IonItem lines="none">
                <IonSelect
                  value={city}
                  placeholder="Select One"
                  onIonChange={(e) => setCity(e.detail.value)}
                >
                  <IonSelectOption value="">Select City</IonSelectOption>
                  {getCityOptions()}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonRow>
              <IonCol size="4">
                <IonButton fill="outline" onClick={handleAddLocation}>
                  Add Location
                  <IonIcon slot="start" icon={addCircleOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonRow>

          <IonRow className="tutor-input-container">
            <IonCol size="12">
              <IonText className="text-s" color="primary">
                Please list your minimum and maximum hourly rate that you would
                like to charge. 100% of this rate will be paid directly to you
                by the customer, using the payment method you prefer. Payments
                will not go through 24-7edu.com. The exact payment rate that you
                will charge to each customer can be negotiated by you with the
                customer, but it should fall within the range that you provide
                below, so that the customer will know your payment range before
                they contact you.
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="tutor-input-container">
            <IonCol size="6" className="tutor-input-wrapper">
              <IonItem lines="none">
                <IonLabel position="stacked">
                  Minimum Hourly Rate (USD)
                </IonLabel>
                <IonInput
                  type="number"
                  value={minRate}
                  onIonChange={(e) => setMinRate(parseInt(e.detail.value!, 10))}
                />
              </IonItem>
            </IonCol>
            <IonCol size="6" className="tutor-input-wrapper">
              <IonItem lines="none">
                <IonLabel position="stacked">
                  Maximum Hourly Rate (USD)
                </IonLabel>
                <IonInput
                  type="number"
                  value={maxRate}
                  onIonChange={(e) => setMaxRate(parseInt(e.detail.value!, 10))}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          {showFormErrors()}
          <IonRow className="tutor-input-container">
            <IonCol>
              <div>
                {error != "updated" ? (
                  <IonButton
                    fill="outline"
                    slot="end"
                    onClick={handleCancelEdit}
                    routerLink="/dashboard"
                  >
                    <IonIcon slot="start" icon={closeCircleOutline} /> Cancel
                  </IonButton>
                ) : (
                  <IonButton
                    fill="outline"
                    slot="end"
                    onClick={() => setError("")}
                    routerLink="/dashboard"
                  >
                    <IonIcon slot="start" icon={arrowBackOutline} /> Dashboard
                  </IonButton>
                )}

                <IonButton
                  fill="outline"
                  slot="end"
                  routerLink="/profile"
                  onClick={handleSaveProfile}
                >
                  <IonIcon slot="start" icon={saveOutline} />
                  Save
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={2000}
      />

      <IonModal
        isOpen={showImageUpload}
        cssClass="tutor-img-upload"
        onDidDismiss={() => setShowImageUpload(false)}
      >
        <div className="tutor-img-upload-wrapper">
          <h4>Edit Your Photo</h4>
          <p className="text-xs">
            Upload your head shot with a minimum 250x250 resolution.
          </p>
          <br />
          <input ref={fileRef} name="tutorImage" type="file" />
          <br />
          <br />
          <IonButton
            size="small"
            fill="outline"
            color="primary"
            onClick={uploadPhoto}
          >
            Upload
          </IonButton>
        </div>
      </IonModal>

      <ChatIcon status="unread" />
    </IonPage>
  );
};

export default Profile;
