/* React Import */
import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
/*****************************************************************************
 *
 * Name : IntroSection
 * Description : Intro section on home page
 * Author : Alex Jacob
 * Date Written : 28/03/2021
 *
 *****************************************************************************/

const IntroSection: React.FC = () => {
  return (
    <div className="intro-section">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-lg="4">
            <img src="assets/images/247edu-logo.png" />
            <br />
            <br />
            <IonText color="light">
              <h1 className="ion-no-padding ion-no-margin">
                Academic Reinforcement & Tutoring
              </h1>
            </IonText>
            <IonText color="primary">
              <h1 className="ion-no-padding ion-no-margin">
                Find someone today, based on
              </h1>
            </IonText>
            <br />
            <div className="intro-highlight">Location</div>
            <div className="intro-highlight">Experience</div>
            <div className="intro-highlight">Schedule</div>
            <br />
            <IonText color="primary">
              <h1 className="ion-no-padding ion-no-margin">And more...</h1>
            </IonText>
            <br />
          </IonCol>
          <IonCol size="12" size-lg="4"></IonCol>
          <IonCol size="12" size-lg="4"></IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
export default IntroSection;
