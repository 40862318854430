/* React Imports */
import React, { useState, useContext } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";
import { UserContext } from "../App";

/* Application Pages */

import Tutor from "../pages/Tutor";
import Acccount from "../pages/Account";
import Search from "../pages/Search";
import Message from "../pages/Message";
import Subscription from "../pages/Subscription";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";


/*****************************************************************************
 * 
 * Name : AppRouter 
 * Description : Routing the paths when a user is logged in 
 * Author : Alex Jacob
 * Date Written : 20/03/2021
 * 
 *****************************************************************************/

const AppRouter: React.FC = () => {
  const [userRole, setUserRole] = useState("");
  const user = useContext(UserContext);
  user.setUserRole = setUserRole;
  return (
    <IonRouterOutlet animated={false}>
      <Route path="/search" component={Search} exact={true} />
      <Route path="/account" component={Acccount} exact={true} />
      <Route path="/tutor/:id" component={Tutor} />
      <Route path="/subscribe" component={Subscription} />
      <Route path="/message" component={Message} />
      <Route path="/dashboard" component={Dashboard} exact={true} />
      <Route path="/profile" component={Profile} exact={true} />
    </IonRouterOutlet>
  );
};
export default AppRouter;
