/* React Imports */
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonChip,
  IonIcon,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import {
  mailOpenOutline,
  pricetagOutline,
  checkmarkOutline,
} from "ionicons/icons";
import { RouteComponentProps } from "react-router";

/* Redux store reducers and actions */
import { RootState } from "../store/reducers";
import { storeSelectedUser } from "../store/actions";

/* Utilities */
import {
  getTutorName,
  getProfileImage,
  getTutorBio,
  getTutoringModes,
  getTutorRates,
  getTutorSubjects,
  getTutorLocations,
  getTutorAvailability,
} from "../Utilities";

/* Application components */
import FooterContainer from "../components/FooterContainer";
import HeaderContainer from "../components/HeaderContainer";

/*****************************************************************************
 *
 * Name : Tutor
 * Description : Tutor Profile page for families with all details of a tutor
 * Author : Alex Jacob
 * Date Written : 24/03/2021
 *
 *****************************************************************************/

const Tutor: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("0");
  const slider = useRef<HTMLIonSlidesElement>(null);
  const results: any = useSelector<RootState>((state) => state.results.results);

  const selectedTutor = {
    id: "",
    name: "",
    image: "",
  };

  let tutorModes: any = null;
  let tutorRates: any = null;
  let tutorBio: any = null;
  let tutorSubjects: any = null;
  let tutorLocations: any = null;
  let tutorAvailability: any = null;

  // read tutor id from the URL
  const tutorId = location.pathname.substring(7, location.pathname.length);

  // loop the results and find the selected tutor profile details
  results.map((result: any, index: any) => {
    if (result.tutor_id === tutorId) {
      selectedTutor["id"] = tutorId;
      selectedTutor["name"] = getTutorName(
        result.profile_summary[0].first_name,
        result.profile_summary[0].last_name
      );
      selectedTutor["image"] = getProfileImage(
        result.profile_summary[0].image
      );
      tutorModes = getTutoringModes(result.profile_summary[0].modality);
      tutorRates = getTutorRates(
        result.pricing[0].min_hourly_rate,
        result.pricing[0].max_hourly_rate
      );
      tutorBio = getTutorBio(result.profile_summary[0].tutor_bio, "");
      tutorSubjects = getTutorSubjects(result.subjects);
      tutorLocations = getTutorLocations(result.locations, result.timezone);
      tutorAvailability = getTutorAvailability(result.schedule_info);
    }
  });

  // handle message button
  const handleMessage = () => {
    dispatch(storeSelectedUser(selectedTutor));
  };
  // function to handle the segment change
  const handleSegmentChange = (e: any) => {
    setValue(e.detail.value);
    slider.current!.slideTo(e.detail.value);
  };

  // function to handle the slider changes
  const handleSlideChange = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    setValue("" + index);
  };

  // slider options
  const slideOpts = {
    initialSlide: 0,
    speed: 100,
    autoHeight: false,
    loop: false,
    pagination: {
      el: null,
    },
  };

  //<IonRow>{getTutorSubjects(result.subjects)}</IonRow>

  return (
    <IonPage>
      <IonContent fullscreen>
      <HeaderContainer backButton={true} role="3" title=""/>
        <div className="tutor-details-profile-img">
          <img src={selectedTutor["image"]} />
        </div>
        <IonGrid fixed={true}>
          <div className="ion-text-center">
            <h1 className="ion-text-center">
              <IonText color="dark">{selectedTutor["name"]}</IonText>
            </h1>
            <IonButton
              fill="outline"
              slot="end"
              routerLink="/message"
              onClick={handleMessage}
            >
              <IonIcon slot="start" icon={mailOpenOutline} />
              Message
            </IonButton>
            <br />
            <br />
            <IonChip>
              <IonIcon icon={checkmarkOutline} />
              <IonLabel>Verified</IonLabel>
            </IonChip>
            {tutorModes}
            <IonChip>
              <IonIcon icon={pricetagOutline} />
              <IonLabel>{tutorRates}</IonLabel>
            </IonChip>
          </div>
          <br />
          <br />
          <IonSegment
            mode="md"
            value={value}
            onIonChange={(e) => handleSegmentChange(e)}
          >
            <IonSegmentButton value="0">
              <IonLabel>Bio</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="1">
              <IonLabel>Subjects</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2">
              <IonLabel>Availability</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonSlides
            pager={true}
            options={slideOpts}
            onIonSlideDidChange={(e) => handleSlideChange(e)}
            ref={slider}
          >
            <IonSlide>
              <IonGrid fixed={true}>
                <br />
                <br />
                <IonRow>
                  <IonCol class="ion-text-left" className="text-xs">
                    <IonText color="dark">{tutorBio}</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonSlide>

            <IonSlide>
              <IonGrid fixed={true}>
                <br />
                <br />
                <IonRow>{tutorSubjects}</IonRow>
              </IonGrid>
            </IonSlide>
            <IonSlide>
              <IonGrid fixed={true}>
                <br />
                <br />
                <IonRow>{tutorLocations}</IonRow>
                <IonRow>{tutorAvailability}</IonRow>
              </IonGrid>
            </IonSlide>
          </IonSlides>
        </IonGrid>

        <br />
        <br />
      </IonContent>
      <FooterContainer />
    </IonPage>
  );
};

export default Tutor;
