import * as actions from "./actions";
import API from "../api";

/* Services */
export const storeServiceList = (svcData:any) => {
  return {
    type: actions.SVCLST,
    data: svcData
  };
};

export const getServices = () => {
  return function (dispatch: any) {
    API
      .getServices()
      .then((result) => {
        dispatch(storeServiceList(result.data.reponseData));
      })
      .catch((error) => {
        console.log("getServices Failed");
      });
  };
};

/* Levels */
export const storeLevels = (svcData:any) => {
  return {
    type: actions.LEVELS,
    data: svcData
  };
};



export const getLevels = (id:any) => {
  return function (dispatch: any) {
    API
      .getLevels(id)
      .then((result) => {
        dispatch(storeLevels(result.data.reponseData));
      })
      .catch((error) => {
        console.log("getLevels Failed");
      });
  };
};

/* Subjects */

export const storeSubjects = (data:any) => {
  return {
    type: actions.SUBJECTS,
    data: data
  };
};

export const getSubjects = (data:any) => {
  return function (dispatch: any) {
    API
      .getSubjects(data)
      .then((result) => {
        dispatch(storeSubjects(result.data.reponseData));
      })
      .catch((error) => {
        console.log("getSubjects Failed");
      });
  };
};

export const getAvailableSubjects = (data:any) => {
  return function (dispatch: any) {
    API
      .getAvailableSubjects(data)
      .then((result) => {
        dispatch(storeSubjects(result.data.reponseData));
      })
      .catch((error) => {
        console.log("getSubjects Failed");
      });
  };
};

/* Schedule */

export const storeSchedule = (data:any) => {
  return {
    type: actions.SCHEDULE,
    data: data
  };
};



