import * as actions from "./actions";
import API from "../api";


export const storeUser = (userData: any) => {
  return {
    type: actions.SIGNIN,
    data: userData,
  };
};

export const storeAction = (data: any) => {
  return {
    type: actions.STOREACTION,
    data: data,
  };
};

export const storeSelectedUser = (userData: any) => {
  return {
    type: actions.SELECTEDTUTOR,
    data: userData,
  };
};

export const storeTutorProfile = (data:any) => {
  return {
    type: actions.STORE_TUTOR_PROFILE,
    data: data
  };
};

export const updatePaymentData = (data:any) => {
  return {
    type: actions.UPDATEPAYMENT,
    data: data,
  };
};

export const updatePayment = (paymentData: any) => {
  return function (dispatch: any) {
    API
      .updatePayment(paymentData)
      .then((result) => {
        dispatch(updatePaymentData(paymentData));
      })
      .catch((error) => {
      });
  };
};

