
/*****************************************************************************
 *
 * Name : FooterContainer
 * Description : Application footer
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/
  const FooterContainer: React.FC = () => {
    return(
       <div className="footer ion-hide-md-down">
           24-7edu.com &#169; 2020 - 2021  All Rights Reserved 
       </div> 
    );
  };
  
  export default FooterContainer;
  