import { combineReducers } from 'redux';
import userReducer from './user';
import chatReducer from './chat';
import serviceReducer from './service';
import searchReducer from './search';
import locationReducer from './location';
import subscriptionReducer from './subscription';

export const rootReducer =  combineReducers({
  user: userReducer,
  chat: chatReducer,
  subscription: subscriptionReducer,
  services: serviceReducer,
  location: locationReducer,
  results: searchReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
