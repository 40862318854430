/* React Imports */
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import React from "react";
import { useSelector} from "react-redux";

/* Redux store reducers */
import { RootState } from "../store/reducers";

/* Application components */
import HeaderContainer from "../components/HeaderContainer";
import ResultContainer from "../components/ResultContainer";
import SearchContainer from "../components/SearchContainer";
import ChatIcon from "../components/ChatIcon";

/*****************************************************************************
 *
 * Name : Search
 * Description : Search Page for Families
 * Author : Alex Jacob
 * Date Written : 24/03/2021
 *
 *****************************************************************************/

const Search: React.FC = () => {
  const userData: any = useSelector<RootState>((state) => state.user.user);
  const welcomeNote = "Welcome, " + userData.first_name;
  return (
    <IonPage>
      <HeaderContainer backButton={false} role="3" title={welcomeNote}/>
      
      <IonContent>
      <SearchContainer />
        <br />
        <br />
        <ResultContainer/>
        <ChatIcon status="unread"/>
      </IonContent>
    </IonPage>
  );
};

export default Search;
