/* React Import */
import React from "react";
import { IonContent, IonPage, IonGrid } from "@ionic/react";

/* Application components */
import HeaderContainer from "../components/HeaderContainer";

/*****************************************************************************
 *
 * Name : Terms
 * Description : Terms and Use Page
 * Author : Alex Jacob
 * Date Written : 28/03/2021
 *
 *****************************************************************************/
const Terms: React.FC = () => {
  

  return (
    <IonPage>
      <IonContent fullscreen>
      <HeaderContainer backButton={true} role="" title="24-7 Edu Terms and Use"/>
      <IonGrid fixed={true} className="privacy-section">
      <br/><br/>    
      Last Updated: September 2020

IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AGREEMENT AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN SECTION 13.

These Terms of Use (the "Terms" or "Agreement") set forth the terms and conditions under which individuals residing in the United States may use the 24-7edu.com Site and/or the 24-7edu.com Platform (as defined below). Certain 24-7edu.com Platforms are subject to additional policies, rules and terms and conditions, which you may be required to agree to as a condition of using those Platform ("Additional Terms"). In those cases, the applicable Additional Terms are set forth in the printed or online Service materials relating to those platforms.

Please read these Terms and any applicable Additional Terms before using the Site or the Platform. By using the Site or the Platform, you hereby represent, warrant, understand, agree to and accept these Terms and any applicable Additional Terms in their entirety whether or not you register as a user of the Site or Platform ("Registered Users").

This Agreement contains an Agreement to Arbitrate, which will, with limited exception, require you to submit claims you have against 24-7edu.com to binding and final arbitration, unless you opt out of the Agreement to Arbitrate (see Section 13 "Agreement to Arbitrate") no later than 30 days after the date you first use the 24-7edu.com Site or Platform, or by October 2020, whichever is later. Unless you opt out: (1) you will only be permitted to pursue claims against 24-7edu.com on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding, and (2) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.

These Terms include the 24-7edu.com Privacy Policy, which is incorporated herein. If you object to anything in these Terms, the Privacy Policy or any applicable Additional Terms, do not use the Site or the Platform.

These Terms are subject to change by 24-7edu.com at any time, subject to the following notification process. We will notify you about material changes in these Terms by sending a notice to the email address registered in your account, or by placing a prominent notice on our Site, so that you can choose whether to continue using our Platform. Material changes will go into effect no less than 30 days after we notify you. Non-material changes or clarifications will take effect immediately upon posting of the updated Terms on our Site. You should periodically check (24-7edu.com/term website) for updates. Any use of the Site or the Platform by you after the effective date of any changes will constitute your acceptance of such changes. These Terms supersede all prior versions of the Terms.

For purposes of these Terms, and except to the extent expressly excluded below, the "Site" shall mean 24-7edu.com and any other websites, web pages, mobile applications and mobile websites operated by 24-7edu.com ("24-7edu.com" or "we") in the United States, and the "Platform" shall mean any of the various services that 24-7edu.com lists through the Site or any other channels, including without limitation, over the telephone. 

However, the terms "Site" and "Platform" do not include or involve the following:

Websites, web pages, mobile applications and mobile websites (and services offered through such channels) operated by 24-7edu.com’s other subsidiaries, including without limitation HOFT Institute’s ESL programs. The sites and services offered by such other subsidiaries are subject to separate terms to which users of such sites and services must separately agree and which can be found on their respective sites and/or service materials or within the relevant mobile applications.

Websites, web pages, mobile applications, and mobile websites (and services offered through such channels) operated by 24-7edu.com which have separate terms of service that do not expressly incorporate these Terms by reference. The sites and services offered by 24-7edu.com via such channels are subject to separate terms to which a user of such sites and services must separately agree and that can be found on their respective sites and/or service materials or within the relevant mobile applications.


Description of Platform; Limitations; User Responsibilities

Eligibility to Use the Site and Platform

Rules For User Conduct and Use of Platform

Background and Verification Checks

Termination

Privacy

Links to External Sites

Payment and Refund Policy

Release of Liability for Conduct and Disputes

Age Restrictions

Disclaimers; Limitations; Waivers; and Indemnification

Copyright Notices/Complaints

Agreement to Arbitrate

Governing Law and Jurisdiction

Consent to Electronic Communication

Miscellaneous

Severability

Contact Information

Description of Platform; Limitations; User Responsibilities

1.1 About Our Platform
24-7edu.com offers a platform to help its users find, coordinate, and maintain tutoring services that meet their needs. The Platform offers, among others:

We enable individuals seeking tutoring services to post a profile on the Site in order to aid in the process of finding families in need of tutoring services ("Independent Contractors").

We provide search functionality on the Site to allow Tutoring Seekers to narrow the pool of Independent Contractors they are interested in meeting based on their needs and preferences, and we provide a communications platform that allows Tutoring Seekers and Independent Contractors to communicate without sharing contact information.

We provide tools and information to help Tutoring Seekers and Independent Contractors make more informed decisions, such as (i) our safety center at (insert website with safety information) which includes a safety guide and safety resources, (ii) verification dashboards on Independent Contractor profiles, which enable Tutoring Seekers to check the status of an Independent Contractor’s various verifications, and (iii) a process for Tutoring Seekers to obtain background check reports from third-party consumer reporting agencies on individual Independent Contractors who consent to the running and sharing of those reports.

We provide functionality on the Site to allow Tutoring Seekers to search for and message other Tutoring Seekers in their geographic area to help Tutoring Seekers to facilitate shared child care services.

We provide a directory of entity Independent Contractors that offer tutoring services from which users may contact or request information.

We offer through Paypal, Inc.., ("Paypal"), a third party, a service that facilitates the payment of Independent Contractors by Tutoring Seekers via credit card or debit card. These payment processing services are provided by Paypal and are subject to the Paypal Connected Account Agreement, which includes the Paypal Terms of Service (collectively, the "Paypal Platform Agreement"). By agreeing to these Terms, Independent Contractors that use the payment service also agree to be bound by the Paypal Platform Agreement, as the same may be modified by Paypal from time to time. As a condition of 24-7edu.com enabling payment processing services through Paypal, you agree to provide 24-7edu.com accurate and complete information about you, and you authorize 24-7edu.com to share it and transaction information related to your use of the payment processing services provided by Paypal. 24-7edu.com assumes no liability or responsibility for any payments you may make through this service, and all such payments are non-refundable. Independent Contractors who elect to receive payments from Tutoring Seekers through this service can also qualify for certain benefits under a program operated by 24-7edu.com.

Our Platform continues to grow and change. Please refer to our Site for further information about updates to our Platform.

1.2. Limitations of our Platform
Our Platform helps our users find, coordinate, and maintain tutoring. However, we do not employ any Independent Contractors. Independent Contractors  are responsible for compliance with all applicable employment and other laws in connection with any employment relationship they establish (such as applicable payroll, tax and minimum wage laws). Further, we do not have control over the quality, timing, or legality of the services actually delivered by Independent Contractors, or of the integrity, responsibility or actions of Tutoring Seekers or Independent Contractors and we neither refer nor recommend Tutoring  Seekers or Independent Contractors nor make any representations about the suitability, reliability, timeliness, or accuracy of the services provided by Independent Contractors or the integrity, responsibility or actions of Tutoring Seekers or Independent Contractors whether in public, private or offline interactions.
Through our Platform, we may provide families and students with a customized list of potential individuals to consider based on the information the family/student provides us regarding their and their loved one's needs and preferences. In providing these services, we are not making a referral to, or endorsing, any individual, company or agency nor are we nor the Independent Contractors providing any medical, diagnostic, treatment or clinical service or engaging in any conduct that requires a professional license.
Any issues concerning agencies, third party vendors such as those we use for background screenings, payment processing or Independent Contractors must be resolved directly with the agency and/or the individual. Tutoring Seeker and Independent Contractor content is primarily user generated, and we do not control or vet user generated content for accuracy. 24-7edu.com does not assume any responsibility for the accuracy or reliability of any information provided by Independent Contractor or Tutoring Seekers on or off this Site. We may offer certain Registered Users the opportunity to verify certain information such as their email address or cell phone number. If we indicate that a Registered User has verified certain information, it means that the user has complied with the process we have established for verifying such information. However, we do not guarantee, nor do we represent or warrant as to, the accuracy of such information.
24-7edu.com is not responsible for the conduct, whether online or offline, of any Tutoring Seeker, Independent Contractor, or other user of the Site or Platform. Moreover, 24-7edu.com does not assume and expressly disclaims any liability that may result from the use of information provided on our Site. All users, including both Tutoring Seekers and Independent Contractors, hereby expressly agree not to hold 24-7edu.com (or 24-7edu.com’s officers, directors, shareholders, employees, subsidiaries, other affiliates, successors, assignees, agents, representatives, advertisers, marketing partners, licensors, independent contractors, recruiters, corporate partners or resellers, hereinafter "Affiliates") liable for the actions or inactions of any Tutoring Seeker, Independent Contractor or other third party or for any information, instruction, advice or services which originated through the Site, and, 24-7edu.com and its Affiliates expressly disclaims any liability whatsoever for any damage, suits, claims, and/or controversies that have arisen or may arise, whether known or unknown therefrom.

1.3. User Responsibilities
Any screening of a Tutoring Seeker or Independent Contractor and his, her or its information by 24-7edu.com is limited and should not be taken as complete, accurate, up-to-date or conclusive of the individual's or entity's suitability as an employer or tutor. Registered Users are solely responsible for interviewing, performing background and reference checks on, verifying information provided by, and selecting an appropriate Tutoring Seeker or Independent Contractor for themselves or their family. Please visit our Safety Center for recommendations on how to make more informed hiring and employment decisions, as well as for tips if you are a Tutoring Seeker looking to find another Tutoring Seeker with whom to share child care services in your area. Each Tutoring Seeker is responsible for complying with all applicable employment and other laws in connection with any employment relationship they establish, including verifying the age of the Independent Contractor they select as well as that Independent Contractor’s eligibility to work in the US. If you are a Parent Registered User (as defined in Section 3.3 below), you also agree to assume the responsibilities set forth in Section 3.3 (Teen Providers, Parent Registered Users, and Parent Monitored Accounts).

Eligibility to Use the Site and Platform
By requesting to use, registering to use and/or using the Site or the Platform, you represent and warrant that you have the right, authority and capacity to enter into these Terms and you commit to abide by all of the terms and conditions hereof. You also represent and warrant that you meet the following eligibility criteria:

Our Platform is available only to individuals who are eighteen (18) years of age or older. If you do not meet this age requirement, do not register to use the Site or Platform.

The Site and Platform are currently available only to individuals who are legally in the United States or the territory of Puerto Rico. 

If you are registering to be an Independent Contractor, you must be permitted to legally work within the United States or Puerto Rico.

Neither you nor any member of your household may have ever been (i) the subject of a complaint, restraining order or any other legal action involving, arrested for, charged with, or convicted of any felony, any criminal offense involving violence, abuse, neglect, fraud or larceny, or any offense that involves endangering the safety of others, dishonesty, negligence or drugs, or (ii) registered, or currently required to register, as a sex offender with any government entity.

You must not be a competitor of 24-7edu.com or using our Platform for reasons that are in competition with 24-7edu.com.

If you are an individual Tutoring Seeker you must not have had fifteen or more employees for each working day in each of twenty or more calendar weeks in the current or preceding calendar year (or five or more if you are seeking services in the State of California).

Rules for User Conduct and Use of Platform

3.1. Registration, Posting, and Content Restrictions
The following rules pertain to "Content", defined as any communications, images, sounds, videos, and all the material, data, and information that you upload or transmit through the Site or Platform, or that other users upload or transmit, including without limitation any content, messages, photos, audios, videos, reviews or profiles that you publish or display (hereinafter, "post"). By posting any Content while using our Service, you agree, represent and warrant as follows:

You are responsible for providing accurate, current and complete information in connection with your registration for use of the Site and the Platform.

You will register your account in your own legal name, even if you are seeking tutoring for another individual or family member.

Unless otherwise permitted by 24-7edu.com, all Content you post will be in English as the Site and Platform generally are not supported in any other languages.

You are solely responsible for any Content that you post on the Site, or transmit to other users of the Site. You will not post on the Site, or transmit to other users, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, defamatory, racially offensive, or illegal material, or any material that infringes or violates another party's rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity), or advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse, or give the impression that any Content emanates from 24-7edu.com where this is not the case. You will not provide inaccurate, misleading, defamatory or false information to 24-7edu.com or to any other user of the Site, and all opinions stated as part of Content must be genuinely held. Without limiting the foregoing, you represent and warrant to us that you have the right and authority to post all information you post about yourself or others, including without limitation that you have authorization from a parent or guardian of any minor who is the subject of any Content you post to post such Content.

You understand and agree that 24-7edu.com may, in its sole discretion, review, edit, and delete any Content, in each case in whole or in part, that in the sole judgment of 24-7edu.com violates these Terms or which 24-7edu.com determines in its sole discretion might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of users of the Site or others.

You have the right, and hereby grant, to 24-7edu.com, its Affiliates, licensees and successors, an irrevocable, perpetual, non-exclusive, fully paid-up, transferable, worldwide license to use, copy, perform, display, reproduce, adapt, modify and distribute your Content and to prepare derivative works of, or incorporate into other works, such Content, and to grant and authorize sublicenses of the foregoing. You further represent and warrant that public posting and use of your Content by 24-7edu.com will not infringe or violate the rights of any third party.

Your use of the Platform, including but not limited to the Content you post on the Site, must be in accordance with any and all applicable laws and regulations.

24-7edu.com is not responsible for any claims relating to any inaccurate, untimely or incomplete information provided by users of the Site.

We welcome your feedback and questions about the Site and Platform. However, you agree that any comments, ideas, messages, questions, suggestions, or other communications you send to us or share with us through any channel (including, without limitation, the Site, email, telephone, surveys, and our social media accounts) shall be and remain the exclusive property of 24-7edu.com and we may use all such communications, all without notice to, consent from, or compensation to you.

Opinions, advice, statements, offers, or other information or content made available on the Site or through the Site, but not directly by 24-7edu.com, are those of their respective authors. Such authors are solely responsible for such content. 24-7edu.com does not: (i) guarantee the accuracy, completeness, or usefulness of any information on the Site or available through the Service, or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party that appears on the Site or through the Service. Under no circumstances will 24-7edu.com or its Affiliates be responsible for any loss or damage resulting from: (a) your reliance on information or other content posted on the Site or transmitted to or by any user of the Site or Service; or (b) reviews or comments made about you on the Site by other users.
You agree that 24-7edu.com has no obligation to remove any reviews or other information posted on the Site about you or any other person or entity. If you disagree with a review, you may post one rebuttal to the review, provided your rebuttal complies with these Terms. You may not terminate your registration and re-register in order to prevent a review from being associated with your account. The author of a review can always remove or request removal of a review they have written.
24-7edu.com disclaims any liability whatsoever for any misstatements and/or misrepresentations made by any users of the Site. Users hereby represent, understand and agree to hold 24-7edu.com harmless for any misstatements and/or misrepresentations made by or on behalf of them on this Site or in any other venue.

3.2. Exclusive Use
If you are a Tutoring Seeker, you may use your account only to find tutoring for yourself, your parents, your children, your grandchildren, individuals for whom you are otherwise the legal guardian or another Tutoring Seeker with whom you are entering a shared tutoring arrangement. If you are an Independent Contractor, you may use your account only to find tutoring jobs for yourself. You are responsible for all activity on and use of your account, and you may not assign or otherwise transfer your account to any other person or entity.

3.3. Prohibited Uses
By using the Site or Platform of 24-7edu.com, you agree that you will not under any circumstances:

use the Site, Platform, or any information contained therein in any way that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable and offensive;

use the Site or Platform for any fraudulent or unlawful purpose, for any purpose not expressly intended by 24-7edu.com or for the promotion of illegal activities;

harass, abuse or harm another person or group, or attempt to do so;

use another user's 24-7edu.com account;

provide false or inaccurate information when registering an account on 24-7edu.com, using the Platform or communicating with other Registered Users;

attempt to re-register with 24-7edu.com if we have terminated your account for any or no reason or terminate your registration and re-register in order to prevent a review from being associated with your account;

interfere or attempt to interfere with the proper functioning of 24-7edu.com's Platform;

make any automated use of the system, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;

bypass any robot exclusion headers or other measures we take to restrict access to the Service or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data (whether manually or through automated means);

use the communication systems provided by or contacts made on 24-7edu.com for any commercial solicitation purposes;

publish or link to malicious content intended to damage or disrupt another user's browser or computer.

In order to protect our users from prohibited activity, we reserve the right to take appropriate actions, including but not limited to restricting the amount of phone numbers a Tutoring Seeker may view or the amount of emails a user may send in any 24-hour period to an amount which we deem appropriate in our sole discretion.
Should 24-7edu.com find that you violated the terms of this Section or any terms stated herein, 24-7edu.com reserves the right, at its sole discretion, to immediately terminate your use of the Site and Platform. By using the Site and/or Platform, you agree that 24-7edu.com may assess, and you will be obligated to pay, $10,000 per each day that you: (i) maintain Independent Contractor or Tutoring Seeker information (including but not limited to, names, addresses, phone numbers, or email addresses) or copyrighted material that you scraped (either directly or indirectly in a manual or automatic manner) from the Site, or (ii) otherwise mis-use or mis-appropriate Site Content, including but not limited to, use on a "mirrored", competitive, or third party site. This obligation shall be in addition to any other rights 24-7edu.com may have under these Terms or applicable law.
Further, in order to protect the integrity of the Site and the Platform, 24-7edu.com reserves the right at any time in its sole discretion to block users from certain IP addresses from accessing the Site.

Background and Verification Checks

4.1 Independent Contractors Can Order or Authorize Background Checks about Themselves and Can Authorize the Sharing of Them with Other Members
24-7edu.com offers to individuals who have registered as Independent Contractors and Tutoring Seekers the following background check services from third-party consumer reporting agencies: Criminal Record Checks, Motor Vehicle Records ("MVR") Checks, Criminal + MVR Records Checks and Investigative Criminal Plus background checks (collectively "Background Checks"). Criminal Records Checks may be ordered by Independent Contractors about themselves or by Tutoring Seekers, subject to the authorization of the Independent Contractor who is the subject of the check. MVR Checks only may be ordered by Independent Contractors about themselves. Criminal + MVR Records Checks only may be ordered by Tutoring Seekers, subject to the authorization of the Independent Contractor who is the subject of the check. Investigative Criminal Plus background checks may only be ordered by Tutoring Seekers subject to the written authorization of the Independent Contractor who is the subject of the check. All background checks require payment of a separate fee by the Member who is initiating the background check request and are subject to the consent of the Independent Contractor on whom the check is being performed.
Each of these Background Checks is regulated by the Fair Credit Reporting Act ("FCRA"), and the background reports resulting from these services are considered "consumer reports" under FCRA. Consumer reports may contain information on your character, general reputation, personal characteristics, and mode of living, including but not limited to consumer credit, criminal history, workers' compensation, driving, employment, military, civil, and educational data and reports.
24-7edu.com will receive a copy of each Background Check you order or authorize through 24-7edu.com. See Section 4.4 below for information regarding 24-7edu.com's use of these Background Checks. You are responsible for making sure that the email address you provide to 24-7edu.com is correct, knowing that sensitive information will be sent to it.
If you authorize a Criminal Records Check or Criminal + MVR Records Check in response to a request by a Tutoring Seeker who is considering hiring you, we will provide both you and the Tutoring Seeker who ordered the check the ability to retrieve the report from the consumer reporting agency that performed the check. If a Tutoring Seeker orders an Investigative Criminal Plus background check pursuant to your written authorization, the consumer reporting agency that performed the check will provide it directly to you and to the Tutoring Seeker who ordered it based on your written authorization. The results of any check ordered pursuant to your written authorization will be shared directly with requesting Tutoring Seeker regardless of its contents.
If you are an Independent Contractor and you have ordered a Background Check from a third-party consumer reporting agency through 24-7edu.com, we may indicate in your profile that you have completed that check. However, we will not share the results with any other site visitor or Registered User without your specific authorization.

4.2. Background Check Offerings Available to Individuals Who May Not Have Registered As Independent Contractors or Tutoring Seekers
Through certain pages of the Site, we may from time to time offer to individuals who may or may not be registered as Tutoring Seekers the opportunity to purchase Investigative Criminal or Investigative Criminal Plus background checks on Independent Contractors who are not necessarily registered on 24-7edu.com. These background checks are performed by a third-party consumer reporting agency and are subject to the terms of Section 4.1 pertaining to Investigative Criminal Plus checks generally, and to Sections 4.3 and 4.7 except that (i) references to Tutoring Seekers shall refer to individuals who order the checks regardless of whether they are registered as Tutoring Seekers, (ii) references to Independent Contractors shall refer to individuals who authorize the check to be performed on themselves regardless of whether they are registered as Independent Contractors, and (iii) 24-7edu.com does not view the results of these checks regardless of whether the Independent Contractor on whom the check is performed is registered as a Independent Contractor.

4.3. Special Responsibilities of Users of Background Check under FCRA
The use of any background check reports obtained through the Site is governed by the federal Fair Credit Reporting Act ("FCRA") and certain state laws. If you order or request access to a background check on an Independent Contractor, you are considered an end user of the check, and must agree to a required End User Certification before the check will be processed. This End User Certification requires you to certify that you will comply with the FCRA and any applicable state laws, and summarizes key legal obligations. A summary of your responsibilities in using the information contained in the background check can be found http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know. If there is negative data in a check you receive, and you choose to take "adverse action" (i.e. if you choose to pass on that individual's candidacy) on the basis of this negative data, you must agree to take certain procedural steps, which can be found at http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know. These include notifying the individual who is the subject of the report of your decision to take adverse action based on information contained in the report and the subject's right to contest the accuracy or completeness of the report. If you have ordered a Criminal Records Check or a Criminal+ MVR Records Check, 24-7edu.com will provide a mechanism for you to request that the consumer reporting agency who performed the check complete these steps on your behalf. If the subject contests the report with the consumer reporting agency, you are required to suspend the hiring process while the agency researches the accuracy and completeness of the report.

4.4 24-7edu.com May Review and Use Background Checks You Order or Authorize About Yourself
By registering for and using the Site or Platform as an individual Independent Contractor, and subject to your authorization, you acknowledge and agree that 24-7edu.com may review and use any Background Checks you have ordered or authorized about yourself for the purpose of protecting the safety and integrity of our Site and its users, which may, in the case of certain Independent Contractors, be considered an employment purpose pursuant to the FCRA. 24-7edu.com reserves the right to terminate your membership based on the information contained in such a report, even if such information was subsequently dismissed.
If 24-7edu.com terminates your membership or access to the Site on the basis of information in a Background Check, we will notify you and provide you the name and contact information of the consumer reporting agency that created the report. We will also provide you a copy of the report unless the consumer reporting agency has already provided you a copy or access to it. You hereby represent, understand and expressly agree that 24-7edu.com does not have control over or assume any responsibility for the quality, accuracy, or reliability of the information included in these Background Checks. Furthermore, any inaccuracies in the report must be addressed with the consumer reporting agency that issued it and not 24-7edu.com.

4.5 24-7edu.com May Order and Use Background Screenings About You
By registering for and using the Site or Platform as a Tutoring Seeker or Independent Contractor, you hereby acknowledge and agree that 24-7edu.com has the right, but not the obligation, to use a third-party consumer reporting agency on an ongoing basis to use your personal information to run periodic background screenings on you for the purpose of protecting the safety and integrity of our Site and its users ("Preliminary Membership Screens"), which may, in the case of certain Independent Contractors, be deemed to be an employment purpose under the FCRA. Subject to certain additional disclosures and authorizations, if applicable to you as a Independent Contractor, 24-7edu.com may order these screenings when you register with 24-7edu.com and thereafter in connection with your continued use of our Platform and/or interaction with our Site (such as by contacting or communicating with other members, posting or updating a job or profile, ordering or authorizing a Background Check, etc.). If you are an Independent Contractor, you agree to the use of a third-party consumer reporting agency to run an initial background check, and you agree to pay for the cost of the background check.
These Internal Background Checks are also regulated by FCRA, and the background reports resulting from these services are considered "consumer reports" under FCRA.
You understand and agree that 24-7edu.com may review the information provided by the third-party consumer reporting agency and that 24-7edu.com retains the right to terminate your 24-7edu.com registration based on the information it receives from these checks, even if such information was subsequently changed or corrected. If 24-7edu.com terminates your membership or access to the Site on the basis of information in a background report, we will notify you and provide you the name and contact information of the consumer reporting agency that created the report. We will also provide you a copy of the report unless the consumer reporting agency has already provided you a copy or access to it. You hereby represent, understand and expressly agree that 24-7edu.com does not have control over or assume any responsibility for the quality, accuracy, or reliability of the information included in these checks. Furthermore, any inaccuracies in the report must be addressed with the consumer reporting agency that issued it (and not 24-7edu.com) within the time period specified in your notice. Notwithstanding this, you agree that 24-7edu.com is under no obligation to reinstate any accounts it may have terminated even if the information that led to the termination is subsequently changed or corrected.
BY AGREEING TO THESE TERMS AND USING OUR SITE, AND, IF APPLICABLE TO YOU AS A CARE PROVIDER, SUBJECT TO YOUR ADDITIONAL AUTHORIZATION, YOU AGREE TO ALLOW 24-7edu.com TO PERFORM THESE INTERNAL BACKGROUND CHECKS FOR THE PURPOSES DESCRIBED ABOVE. IF YOU DO NOT WANT THESE INTERNAL BACKGROUND CHECKS TO BE PERFORMED, YOU SHOULD NOT USE 24-7edu.com.

4.6. 24-7edu.com May Regularly Verify Your Identity and the Accuracy of Your Representations and Warranties
By registering as a Independent Contractor or Tutoring Seeker, and, if applicable to you as a Independent Contractor, subject to your additional authorization, you authorize 24-7edu.com, and acknowledge that for purposes of promoting the safety and integrity of its Site and Service, 24-7edu.com reserves the right, but not the obligation, to utilize third party service providers to verify on an ongoing basis that your registration data is accurate and that the representations and warranties in Section 2 above addressing legal matters such as complaints, arrests, sex offender status, etc. are also true ("Verification Checks"). These third parties may use data from a variety of sources, under a variety of circumstances, for these site safety purposes including, without limitation, information from national criminal databases, sex offender registries, certain media streams, terrorist watch lists, criminal and fugitive watch lists, fraud watch lists, law enforcement reports, and other data.
You agree that 24-7edu.com may take such action in response to Verification Checks as it deems appropriate in its sole discretion, including without limitation suspending and/or terminating your membership, should it determine that you have violated any representation or warranty or any other provision of these Terms or are otherwise unsuitable for 24-7edu.com.
You also hereby represent, understand and expressly agree that 24-7edu.com does not have control over or assume any responsibility for the quality, accuracy, or reliability of the information included in a Verification Check. We do not typically communicate the results of a Verification Check to any third party, though we reserve the right to do so for law enforcement or other safety-related purposes in accordance with applicable laws.
BY AGREEING TO THESE TERMS, AND, IF APPLICABLE TO YOU AS A CARE PROVIDER SUBJECT TO YOUR ADDITIONAL AUTHORIZATION, YOU AGREE TO ALLOW 24-7edu.com TO PERFORM THE VERIFICATION CHECKS DESCRIBED ABOVE. IF YOU DO NOT WANT THESE VERIFICATION CHECKS TO BE PERFORMED, YOU SHOULD NOT USE 24-7edu.com.

4.7 Important Limitations About Background Checks; Release of Liability for Results of Background Checks, Internal Background Checks, and Verification Checks
SPECIAL NOTICE FOR MASSACHUSETTS AND NEW HAMPSHIRE: Criminal County Searches conducted in Massachusetts and New Hampshire as part of the Criminal Records Check and the Criminal+MVR Records Check (for individuals who are believed to have lived in one or both of these states during the seven years preceding the background check request) search only the Superior Court and a single District Court in the county seat or its equivalent for each county listed below. Criminal records (including misdemeanors and felonies) from other courts in these states will not be searched.
This is an illustrative, non-comprehensive, list of certain state and county limitations of the background checks offered through 24-7edu.com; additional limitations may apply in certain jurisdictions, including on the federal level. For example, for many states only certain registered sex offender information is reported, and for many states, only a subset of felony convictions are reported (and not any misdemeanors, charges or arrest records).
In addition to legal and reporting system limitations of background checks, each check is performed with the authorization of the person being checked, using information he or she provides, such as home address, social security number, date of birth, and name. If a candidate provides incorrect information, the check might be run with inaccurate identifying data, which can impact the validity of the criminal check. Finally, criminal records are not always reported accurately or promptly, and human and electronic error can result in inaccurate or incomplete reporting. Consequently, even the most comprehensive background check offered may not disclose the existence of all criminal records in all jurisdictions.
If you decide to access, use, or share information provided by a Background Check, you agree to do so in accordance with applicable law. You also agree to release, indemnify and hold harmless 24-7edu.com from any loss, liability, injury, death, damage, or costs that may result from your use of, reliance on, or sharing of the information contained in a Background Check regardless of the cause, including, without limitation, due to the inaccuracy or incompleteness of any such information.
You expressly acknowledge that 24-7edu.com has no obligation to perform Background Checks, Internal Background Checks, or Verification Checks on any Registered Users. To the extent 24-7edu.com performs such checks on certain Registered Users, the checks are limited and should not be taken as complete, accurate, up-to-date or conclusive evidence of the accuracy of any information those users have provided or of their eligibility to use the Platform.

Termination
24-7edu.com reserves the right, in its sole discretion, to immediately terminate your access to all or part of the 24-7edu.com Site and/or Platform, to remove your profile and/or any content posted by or about you from the Site, and/or to terminate your account with 24-7edu.com, with or without notice for any reason or no reason in its sole discretion, including without limitation if 24-7edu.com should determine that you are not eligible to use the Platform, have violated any terms stated herein or in any of the Additional Terms, are not suitable for participation as a Registered User, have mis-used or mis-appropriated Site content, including but not limited to use on a "mirrored," competitive, or third-party site. Upon termination, 24-7edu.com shall be under no obligation to provide you with a copy of any content posted by or about you on the Site. If we terminate your registration, we have no obligation to notify you of the reason, if any, for your termination.
Following any termination of any individual's use of the Site or the Platform, 24-7edu.com reserves the right to send a notice thereof to other Registered Users with whom we believe the individual has corresponded. Our decision to terminate an individual's registration and/or to notify other Registered Users with whom we believe the individual has corresponded does not constitute, and should not be interpreted or used as information bearing on, the individual's character, general reputation, personal characteristics, or mode of living.

Privacy
24-7edu.com uses the information you provide on the Site or via the Platform or in accordance with our Privacy Policy. For more information, see our full Privacy Policy, the terms of which are incorporated herein.

Links To External Sites
Links from the Site to external sites (including external sites that are framed by 24-7edu.com) or inclusion of advertisements and other third-party content do not constitute an endorsement by 24-7edu.com of such sites or the content, products, advertising and other materials presented on such sites or of the products and services that are the subject of such third-party content, but are for users' reference and convenience.
Users access them at their own risk. It is the responsibility of the user to evaluate the content and usefulness of the information obtained from other sites. 24-7edu.com does not control such sites, and is not responsible for their content.
Users further acknowledge that use of any site or content controlled, owned or operated by third parties is governed by the terms and conditions of use for those sites, and not by 24-7edu.com's Terms of Use and Privacy Policy. 24-7edu.com expressly disclaims any liability derived from the use and/or viewing of links that may appear on this Site. All users hereby agree to hold 24-7edu.com harmless from any liability that may result from the use of links that may appear on the Site.

Payment And Refund Policy
In order to utilize some 24-7edu.com Platform or product offerings, the user of such Platform or product offerings must pay the Independent Contractor after their tutoring session is complete through the third-party vendor service that 24-7edu.com has integrated into the platform. In addition, the user is responsible for any state or local sales taxes associated with the Platform or product offerings purchased. 

8.1 Billing and Payment
If you sign up for a 24-7edu.com tutoring session through an Independent Contractor, you agree to pay the Independent Contractor all charges associated with the tutoring sessions you registered for immediately after they’ve completed them as described on the Site at the time you subscribe and provide your payment information. You also authorize 24-7edu.com, or a third-party payment processor that works on our behalf, to charge your chosen payment method according to the terms of the plan to which you subscribe. The amount and frequency charged to you will depend on the rate of your tutor (Independent Contractor) and how often you receive tutoring services. 24-7edu.com reserves the right to correct any errors or mistakes that it makes even if it has already requested or received payment.
To the extent you elect to purchase other Platform or product offerings we may offer for a fee, you authorize 24-7edu.com to charge your chosen payment provider for the Platform and/or tutoring services you sign up for after they’ve completed. You agree that if 24-7edu.com already has your credit card on file as a result of prior purchases you have made, we may charge that credit card for the additional tutoring sessions you book.

8.3 Free Trial Offers
24-7edu.com may offer a limited-time initial free tutoring session or discounted session to certain users from time-to-time. Users who sign up for a 24-7edu.com Service on a free trial basis may have limited access to the Service and/or features of the Site. If a user no longer wishes to pursue tutoring services after the free tutoring session is complete or after using a discount towards a tutoring session, they may cancel at any time. However, any amount owed after completing a tutoring session will still be charged after the session has ended.

8.5 Refund Policy
Except as set forth in these Terms or as described on the Site at the time you make a purchase, all payments for services/products are non-refundable and there are no refunds or credits for unused or partially used services/products or service/product cancellations. In addition, all matters pertaining to a refund must be taken directly to the Independent Contractor associated with the charges and tutoring session, with attempts made to work directly with that Independent Contractor on receiving a refund. 24-7edu.com does not provide refunds or credits under any other circumstances, unless it determines in its sole discretion that a refund or credit is warranted due to extenuating circumstances, such as a duplicate account.

Release of Liability for Conduct and Disputes
24-7edu.com is not an employer of Independent Contractors. Tutoring Seekers may seek the services of an Independent Contractor through the use of the Site or Platform, and Independent Contractors may post profiles and submit proposals to Tutoring Seekers regarding their services.
If a Tutoring Seeker agrees on the provision of services from an individual, company or agency we or the Platform identifies, such agreement is solely between the Tutoring Seeker and the Independent Contractor; 24-7edu.com is not a party to any such agreement.
In some instances, such as through the Initial Background Screening process, the third parties we contract with to provide services in support of that Service select the individual Backup Independent Contractors from their agency who provide the service. We contract with reputable agencies that have entered into an agreement with us pursuant to which they represent that each of their individual Backup Independent Contractors has passed a screening process including background and reference checks. However, each such agency operates independently of 24-7edu.com and is not under our control. Any issues concerning the conduct of a Tutoring Seeker or Independent Contractor including, without limitation, the services received by the Tutoring Seeker or payment due to the Independent Contractor, must be resolved directly by the Tutoring Seeker and the Independent Contractor. 24-7edu.com will not be held responsible and expressly disclaims any liability whatsoever for any claims, demands or damages direct or indirect of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such issues.
By using this Site or our Platform, you hereby represent, understand, and expressly agree to hold 24-7edu.com harmless for any claim or controversy that may arise out of the actions of or relationship between you and any Tutoring Seeker, Independent Contractor or other user(s) of the Site that are not Backup Independent Contractors employed by us. You agree to take reasonable precautions in all interactions with Tutoring Seekers, Independent Contractors or other users of the Site or the Platform, particularly if you decide to meet offline. In addition, you agree to visit the 24-7edu.com Safety Center prior to using the Service for additional helpful information. By using the Site or the Platform, you agree to report any alleged improprieties of any users therein to 24-7edu.com immediately by notifying 24-7edu.com of the same via electronic correspondence.

Age Restrictions
24-7edu.com is intended for people 18 or over. 24-7edu.com will not knowingly collect any information from individuals under 13. Should we determine that you do not meet the age requirements for using our Site or Platform, your registration will be terminated immediately.

Disclaimers; Limitations; Waivers; Indemnification

11.1. No Warranty
The information and materials contained on the Site, including text, graphics, information, links or other items are provided "as is," "as available." Further, opinions, advice, statements, offers, or other information or content made available through the Platform, but not directly by 24-7edu.com, are those of their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content. 24-7edu.com DOES NOT: (1) WARRANT THE ACCURACY, ADEQUACY OR COMPLETENESS OF THIS INFORMATION AND MATERIALS; (2) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN 24-7edu.com; (3) WARRANT THAT YOUR USE OF THE SITE OR SERVICES WILL BE SECURE, FREE FROM COMPUTER VIRUSES, UNINTERRUPTED, ALWAYS AVAILABLE, ERROR-FREE OR WILL MEET YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE SERVICES WILL BE CORRECTED; OR (4) GIVE ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, 24-7edu.com EXPRESSLY EXCLUDES ALL CONDITIONS, WARRANTIES AND OTHER TERMS WHICH MIGHT OTHERWISE BE IMPLIED BY STATUTE, COMMON LAW OR THE LAW OF EQUITY AND DISCLAIMS LIABILITY FOR ERRORS OR OMISSIONS IN THIS INFORMATION AND MATERIALS.
IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, 24-7edu.com MAKES NO REPRESENTATION OR WARRANTIES OF ANY KIND WHETHER EXPRESS OR IMPLIED REGARDING THE SUITABILITY OF ANY USER OF OUR SITE TO PROVIDE SERVICES AS A CARE PROVIDER OR TO EMPLOY THE SERVICES OF A CARE PROVIDER.
WITH RESPECT TO CORPORATE CARE SEEKERS, THE INFORMATION AND MATERIAL CONTAINED ON THE SITE IS PROVIDED FOR YOUR INTERNAL USE ONLY AND MAY NOT BE COPIED OR REDISTRIBUTED FOR ANY REASON. THIS INFORMATION IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. THE INFORMATION IS THE INTELLECTUAL PROPERTY OF THE CORPORATE CARE SEEKER, 24-7edu.com OR ITS INFORMATION PROVIDERS. IN NO EVENT WILL 24-7edu.com OR ITS INFORMATION PROVIDERS BE LIABLE IN ANY WAY WITH REGARD TO SUCH INFORMATION. YOUR USE OF THE INFORMATION MUST IN ALL CASES COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS.

11.2. Assumption of Risk
You assume all risks when using the Site and the Platform, including but not limited to all of the risks associated with any online or offline interactions with users of the Site or the Platform. You agree to take all necessary precautions, including but not limited to reviewing the recommendations set forth in 24-7edu.com's Safety Center when interacting with other site visitors or Registered Users.

11.3. Limitation of Liability
Incidental Damages and Aggregate Liability. In no event will 24-7edu.com be liable for any indirect, special, incidental, or consequential damages, losses or expenses arising out of or relating to the use or inability to use the Site or Platform, including without limitation damages related to any information received from the Site or Platform, removal of content from the Site, including profile information, any email distributed to any user or any linked web site or use thereof or inability to use by any party, or in connection with any termination of your subscription or ability to access the Site or Platform, failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if 24-7edu.com, or representatives thereof, are advised of the possibility of such damages, losses or expenses. UNDER NO CIRCUMSTANCES WILL 24-7edu.com'S AGGREGATE LIABILITY, IN ANY FORM OF ACTION WHATSOEVER IN CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SERVICES OR THE SITE, EXCEED THE PRICE PAID BY YOU FOR YOUR ACCOUNT, OR, IF YOU HAVE NOT PAID 24-7edu.com FOR THE USE OF ANY SERVICES, THE AMOUNT OF $25.00.
No Liability for non-24-7edu.com Actions. IN NO EVENT WILL 24-7edu.com BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SITE OR THE SERVICES OR ANY AGREEMENT OR RELATIONSHIP FORMED USING THE SITE OR SERVICE, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM ANYONE'S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE SITE, OR TRANSMITTED TO OR BY ANY USERS OR ANY OTHER INTERACTIONS WITH OTHER REGISTERED USERS OF THE SITE OR SERVICES, WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM YOU.
In addition to the preceding paragraphs of this section and other provisions of these Terms, any advice that may be posted on the Site is for informational purposes only and is not intended to replace or substitute for any professional financial, medical, legal, or other advice. 24-7edu.com makes no representations or warranties and expressly disclaims any and all liability concerning any treatment, action by, or effect on any person following the information offered or provided within or through the Site. If you have specific concerns or a situation arises in which you require professional or medical advice, you should consult with an appropriately trained and qualified specialist.

11.4. Indemnification
By agreeing to these Terms, users of the Site and Platform agree to indemnify, defend and hold harmless 24-7edu.com and its Affiliates from and against any and all claims, losses, expenses or demands of liability, including reasonable attorneys' fees and costs incurred by 24-7edu.com and its Affiliates in connection with any claim by a third party (including an intellectual property claim) arising out of (i) materials and content you submit, post or transmit through the Site, (ii) use of the Site or Platform by you in violation of these Terms of Use or in violation of any applicable law, or (iii) any relationship or agreement formed with a Tutoring Seeker or Independent Contractor using the Site or Platform. Users further agree that they will cooperate as reasonably required in the defense of such claims. 24-7edu.com and its Affiliates reserve the right, at their own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by users, and users shall not, in any event, settle any claim or matter without the written consent of 24-7edu.com. Users further agree to hold harmless 24-7edu.com and its Affiliates from any claim arising from a third party's use of information or materials of any kind that users post to the Site.

Copyright Notices/Complaints
It is 24-7edu.com's policy to respond to notices of alleged copyright infringement with the Digital Millennium Copyright Act ("DMCA"). If you believe any materials accessible on or from our Site infringe your copyright, you may request removal of those materials (or access thereto) from the Site by contacting 24-7edu.com's copyright agent (identified below) and providing the following information:

Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work.

Identification of the material that you believe to be infringing and its location. Please describe the material, and provide us with its URL or any other pertinent information that will allow us to locate the material.

Your name, address, telephone number and (if available) e-mail address.

A statement that you have a good faith belief that the complaint of use of the materials is not authorized by the copyright owner, its agent, or the law.

A statement that the information that you have supplied is accurate, and indicating that "under penalty of perjury," you are the copyright owner or are authorized to act on the copyright owner's behalf.

A signature or the electronic equivalent from the copyright holder or authorized representative.
In an effort to protect the rights of copyright owners, 24-7edu.com maintains a policy for the termination, in appropriate circumstances, of Members and other users of this Site who are repeat infringers.

Agreement to Arbitrate

13.1 Agreement to Arbitrate
This Section 13 is referred to in these Terms as the "Arbitration Agreement". Unless you opt-out in accordance with the opt-out procedures set forth in Section 13.8 below, you agree that all claims relating to or arising out of these Terms or the breach thereof, whether sounding in contract, tort, or otherwise that have arisen or may arise between you and 24-7edu.com or a 24-7edu.com Affiliate, whether relating to these Terms (including any alleged breach thereof), the Platform, the Site, or otherwise, shall be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement, except you may assert individual claims in small claims court, if your claims qualify. Your rights will be determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.

13.2 Prohibition of Class and Representative Actions and Non-Individualized Relief
YOU AND 24-7edu.com AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ARBITRATION, ACTION OR PROCEEDING. UNLESS BOTH YOU AND 24-7edu.com EXPRESSLY AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AGAINST 24-7edu.com AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER 24-7edu.com USERS. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision set forth above shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.

13.3 Pre-Arbitration Dispute Resolution
24-7edu.com is always interested in resolving disputes amicably and efficiently. So before you commence arbitration, we suggest that you contact us to explain your complaint, as we may be able to resolve it without the need for arbitration. You may contact us via email at support@24-7edu.com.

13.4 Arbitration Procedures
If we cannot resolve a Claim informally, any Claim either of us asserts will be resolved only by binding arbitration and not in courts of general jurisdiction. Arbitration will be conducted by a neutral arbitrator in accordance with the rules of JAMS that are in effect at the time the arbitration is initiated (collectively referred to as the "JAMS Rules"), as modified by this Arbitration Agreement, and excluding the JAMS Class Action Procedures. For information on JAMS, please visit its website, https://www.jamsadr.com/. Information about JAMS's Rules and fees for consumer disputes can be found at the JAMS consumer arbitration page, https://www.jamsadr.com/rules-comprehensive-arbitration/. If there is any inconsistency between the JAMS Rules and this Arbitration Agreement, the terms of this Arbitration Agreement will control unless the arbitrator determines that the application of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair arbitration. The arbitrator must also follow the provisions of these Terms as a court would, including without limitation, the limitation of liability provisions in Section 11. Although arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the same damages and relief on an individual basis that a court can award to an individual under the Terms and applicable law. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons.
To commence an arbitration against 24-7edu.com, you must write a demand for arbitration that includes a description of the dispute and the amount of damages sought to be recovered. You can find a copy of a Demand for Arbitration at www.jamsadr.com. You should send three copies of the Demand, plus the appropriate filing fee, to JAMS at 500 North State College Blvd., Suite 600, Orange, CA 92868, and send one copy to 24-7edu.com at 24-7edu.com, Inc., 2400 Pearl Street, Austin, TX 78705.  For more information, see the JAMS arbitration rules and forms, https://www.jamsadr.com/rules-download/. You may represent yourself in the arbitration or be represented by an attorney or another representative. Once we receive your arbitration claim, we may assert any counterclaims we may have against you.
The arbitration shall be held in the county in which you reside or at another mutually agreed location. If the value of the relief sought is $10,000 or less you or 24-7edu.com may elect to have the arbitration conducted by telephone or based solely on written submissions, which election shall be binding on you and 24-7edu.com subject to the arbitrator's discretion to require an in-person hearing, if the circumstances warrant. Attendance at any in-person hearing may be made by telephone by you and/or 24-7edu.com, unless the arbitrator requires otherwise.
The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all claims relating to or arising out of this contract, or the breach thereof, whether sounding in contract, tort, or otherwise and all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of these Terms, including, but not limited to any claim that all or any part of these Terms are void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator's award shall be written, and binding on the parties and may be entered as a judgment in any court of competent jurisdiction.
The arbitrator will decide the substance of all claims in accordance with the laws of the State of Delaware, including recognized principles of equity, and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different 24-7edu.com users, but is bound by rulings in prior arbitrations involving the same 24-7edu.com user to the extent required by applicable law.

13.5 Costs of Arbitration
Payment of all filing, administration, and arbitrator fees (collectively, the "Arbitration Fees") will be governed by the JAMS Rules, unless otherwise provided in this Agreement to Arbitrate. If you demonstrate to the arbitrator that you are economically unable to pay your portion of the Arbitration Fees or if the arbitrator otherwise determines for any reason that you should not be required to pay your portion of the Arbitration Fees, 24-7edu.com will pay your portion of such fees. In addition, if you demonstrate to the arbitrator that the costs of arbitration will be prohibitive as compared to the costs of litigation, 24-7edu.com will pay as much of the Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive. Each party will be responsible for all other fees it incurs in connection with the arbitration, including without limitation, all attorney fees. In the event the arbitrator determines the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse 24-7edu.com for all fees associated with the arbitration paid by 24-7edu.com on your behalf that you otherwise would be obligated to pay under the JAMS rules.

13.6 Confidentiality
All aspects of the arbitration proceeding, and any ruling, decision or award by the arbitrator, will be strictly confidential for the benefit of all parties.

13.8 Opt-Out Procedure
You can choose to reject this Arbitration Agreement by mailing us a written opt-out notice ("Opt-Out Notice") in accordance with the terms of this Section. For new 24-7edu.com users, the Opt-Out Notice must be postmarked no later than 30 Days after the date you use our Site or Platform for the first time. If you are already a current 24-7edu.com user and previously accepted the 24-7edu.com Terms prior to the introduction of this Arbitration Agreement, the Opt-Out Notice must be postmarked no later than January 17, 2017. You must mail the Opt-Out Notice to 24-7edu.com, (2400 Pearl Street, Austin, TX 78705). The Opt-Out Notice must state that you do not agree to the Arbitration Agreement and must include your name, address, phone number, and the email address(es) used to log in to the 24-7edu.com account(s) to which the opt-out applies. You must sign the Opt-Out Notice for it to be effective. This procedure is the only way you can opt out of the Arbitration Agreement. If you opt out of the Arbitration Agreement, 24-7edu.com will likewise not be bound by these arbitration provisions. All other terms of these Terms will continue to apply. Opting out of the Arbitration Agreement has no effect on any previous, other, or future arbitration agreements that you may have with us. 24-7edu.com users who accepted a previous version of these Terms that included an arbitration agreement, and who did not timely opt out of that arbitration agreement, remain bound by the last arbitration agreement that they accepted. Upon receipt of a valid Opt-Out Notice, 24-7edu.com will provide the opting out user with a copy of the arbitration agreement from the last version of the Terms that the user accepted, if any exists.

13.9 Future Changes to this Arbitration Agreement
Notwithstanding any provision in these Terms to the contrary, you and we agree that if we make any change to this Arbitration Agreement (other than a change to any notice address or website link provided herein) in the future, such change shall not be effective until at least 60 days from the date of posting, and shall not apply to any claim that was filed in a legal proceeding against 24-7edu.com prior to the effective date of the change. Moreover, if we seek to terminate this Arbitration Agreement from these Terms, such termination shall not be effective until 30 days after the version of these Terms not containing the Arbitration Agreement is posted to the Site, and shall not be effective as to any claim that was filed in a legal proceeding against 24-7edu.com prior to the effective date of removal.

Governing Law and Jurisdiction
These Terms, and all claims relating to or arising out of this contract, or the breach thereof, whether sounding in contract, tort, or otherwise, shall be governed by the laws of the State of Delaware, including Delaware's statutes of limitations governing your claim, without giving effect to its principles of conflicts of law, provided that the Federal Arbitration Act shall govern the interpretation and enforcement of Section 13, the Arbitration Agreement.
Unless you and we agree otherwise, in the event that the Arbitration Agreement is found not to apply to you or to a particular claim or dispute (except for small-claims court actions), either as a result of your decision to opt-out of the Arbitration Agreement or as a result of a decision by the arbitrator or a court order, you agree that any claim or dispute that has arisen or may arise between you and 24-7edu.com must be resolved exclusively by a state or federal court located in the State of Delaware. You and 24-7edu.com agree to submit to the personal jurisdiction of the courts located within the State of Delaware for the purpose of litigating all such claims or disputes.

Consent to Electronic Communication
By using the Site or Platform of 24-7edu.com, you agree to allow 24-7edu.com to communicate with you electronically, and you consent to electronic delivery of notices, documents, or products (including, without limitation, reports or copies of Background Checks and Preliminary Membership Screens) from 24-7edu.com via the 24-7edu.com Site, mobile application, online messaging platform, or e-mail. You also agree to check your 24-7edu.com account, alerts, and messages, and the e-mail account reflected on your 24-7edu.com on a reasonably regular basis to stay apprised of important notices and information about your account.

Miscellaneous
Nothing in this Agreement shall be construed as making either party the partner, joint venturer, agent, legal representative, employer, contractor or employee of the other. Neither party shall have, or hold itself out to any third party as having any authority to make any statements, representations or commitments of any kind, or to take any action, that shall be binding on the other, except as provided for herein or authorized in writing by the party to be bound. The invalidity, illegality or unenforceability of any term or provision of these Terms shall in no way effect the validity, legality or enforceability of any other term or provision of these Terms. Each Affiliate (as defined in Section 1.2) is expressly made a third party beneficiary of this Agreement and may enforce this Agreement directly against you. This Agreement will be binding on and will inure to the benefit of the legal representatives, successors and assigns of the parties hereto.

Severability
If a court decides that any term or provision of these Terms other than Section 13.2 is invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or provision, and these Terms shall be enforceable as so modified. If a court decides that any of the provisions of Section 13.2 are invalid or unenforceable, then the entirety of Section 13 shall be null and void. The remainder of the Terms will continue to apply.

Contact Information
If you have any questions or need further information as to the Site or Platform provided by 24-7edu.com, or need to notify 24-7edu.com as to any matters relating to the Site or Platform please contact 24-7edu.com at: 2400 Pearl Street, Austin, TX 78705/support@24-7edu.com/512-472-6666

</IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Terms;
