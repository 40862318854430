/* React Imports */
import React, { useState, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/*Application styles */
import "./theme/app.css";

/* Application Pages*/
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Home from "./pages/Home";
import Verification from "./pages/Verification";

/* Application components */
import AppRouter from "./components/AppRouter";
import Activation from "./pages/Activation";


/* User Context */
interface UserManager {
  setIsLoggedIn: Function;
  setUserRole: Function;
}

const user: UserManager = {
  setIsLoggedIn: () => {},
  setUserRole: () => {},
};

export const UserContext = React.createContext<UserManager>(user);

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useContext(UserContext);
  user.setIsLoggedIn = setIsLoggedIn;
  //<Redirect exact from="/" to="/" />

  return (
    <IonApp>
      <UserContext.Provider value={user}>
        <IonReactRouter>
          <Route path="/" component={isLoggedIn ? AppRouter : Home} />
          <Route path="/activate/:id" component={Activation} exact={true} />
          <Route path="/verification/:id" component={Verification} exact={true} />
          <Route path="/privacy" component={Privacy} exact={true} />
          <Route path="/terms" component={Terms} exact={true} />
        </IonReactRouter>
      </UserContext.Provider>
    </IonApp>
  );
};

export default App;
