/* React Imports */
import {
  IonButton,
  IonIcon,
} from "@ionic/react";
import {
  mailUnreadOutline,
  mailOpenOutline,
} from "ionicons/icons";
import { useDispatch } from "react-redux";

/* Redux store actions */
import {
  storeSelectedUser,
} from "../store/actions";

/* Interface for props */
interface ChatProps {
  status: string;
}

/*****************************************************************************
 *
 * Name : ChatIcon
 * Description : Create the chat Icon on Dashboard/Search
 * Author : Alex Jacob
 * Date Written : 27/03/2021
 *
 *****************************************************************************/

const ChatIcon: React.FC<ChatProps> = ({ status }) => {
  const dispatch = useDispatch();
  const handleChat = () =>
  {
    dispatch(storeSelectedUser([]));
  }
  return (
    <IonButton className="chat-container" routerLink="/message" onClick={handleChat}>
      {status === "unread" ? (
        <IonIcon className="chat-icon" icon={mailUnreadOutline} color="light" />
      ) : (
        <IonIcon className="chat-icon" icon={mailOpenOutline} color="light" />
      )}
    </IonButton>
  );
};

export default ChatIcon;
