/* React Imports */
import React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";

/* Redux store actions */
import {
  getSubscription,
} from "../store/actions";

/*****************************************************************************
 *
 * Name : Verification
 * Description : Verification page after paypal payment
 * Author : Alex Jacob
 * Date Written : 24/03/2021
 *
 *****************************************************************************/

const Verification: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const verification_id = location.pathname.substring(
    14,
    location.pathname.length
  );
  const subscribe_id = localStorage.getItem("subscribe_id");

  console.log(verification_id)
  console.log(subscribe_id)
  console.log(localStorage.getItem("paypaltoken"))
  if (
    verification_id !== null &&
    subscribe_id != null &&
    localStorage.getItem("paypaltoken")
  ) {
    if (
      verification_id ==
      "Acvq_gZYGOxmiIF_duBRV75B6MiraAx3iXIJ4OjjftEyOUJrxvHoqsOwLRaFplBATUOYHxcU8I8dVKfk"
    ) {
      dispatch(getSubscription(subscribe_id));
    }
  }
  return <></>;
};

export default Verification;
