/* React Import */
import React, { useContext, useState } from "react";
import {
  IonLoading,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonCheckbox,
  IonRadioGroup,
  IonIcon,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

/* Redux store actions */
import {
  getServices,
  getStates,
  getLevels,
  storeUser,
  getChatHeads,
  getSubscription,
  getTimezones,
} from "../store/actions";

/* Application APIs */
import API from "../store/api";

/* User context from application */
import { UserContext } from "../App";

/* Utilities */
import { validateRegistration } from "../Utilities";
import { Icon } from "ionicons/dist/types/components/icon/icon";
import { checkmarkCircleOutline } from "ionicons/icons";

/*****************************************************************************
 *
 * Name : Registration
 * Description : Registration Modal Window for Tutors and Family
 * Author : Alex Jacob
 * Date Written : 28/03/2021
 *
 *****************************************************************************/

const Registration: React.FC = () => {
  const [fname, setFname] = useState<string>("");
  const [lname, setLname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cfmPassword, setCfmPassword] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [signupCompleted, setSignupCompleted] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useContext(UserContext);

  const selectInterface = {
    cssClass: "select-options",
  };

  const regiterForm = {
    fname: fname,
    lname: lname,
    email: email,
    password: password,
    cfmPassword: cfmPassword,
    role: role,
    checked: checked,
  };
  const registerUser = () => {
    let validationError = validateRegistration(regiterForm);

    if (validationError === "") {
      setShowLoading(true);

      const authData = {
        firstName: fname,
        lastName: lname,
        emailAddress: email,
        password: password,
        role: role,
      };

      API.userSignup(authData)
        .then((result) => {
          setShowLoading(false);
          setSignupCompleted(true);

          /*
          Commented this logic for email activation requirement, previously users were able to login along with singup

          // store the 247 user token in local storage and save user details in store
          localStorage.setItem("token", result.data.reponseData.accessToken);
          dispatch(storeUser(result.data.reponseData));

          // set the login status and role in user context
          user.setIsLoggedIn(true);

          // if logged user is tutor
          if (role === "2") {
            history.push("/dashboard");
            dispatch(getChatHeads([]));
            dispatch(getServices());
            dispatch(getStates());
            dispatch(getTimezones());
          }
          // logged user is family
          else {
            history.push("/search");

            // get all the required dropdown box values for the search interface
            dispatch(getServices());
            dispatch(getStates());
            dispatch(getChatHeads([]));

            let paymentStatus = result.data.reponseData.paymentStatus;
            let subscribe_id = result.data.reponseData.paypal_sub_id;

            /******************************************************************************************
             * Payapl Authentication and get the subscription status of the user
             *******************************************************************************************
            API.paypalAuth()
              .then((result) => {
                localStorage.setItem("paypaltoken", result.data.access_token);
                if (paymentStatus == "2" && subscribe_id != "") {
                  dispatch(getSubscription(subscribe_id));
                }
              })
              .catch((error) => {});
          }*/
        })
        .catch((error) => {
          if (error.response) {
            setMessage(error.response.data.message);
          } else {
            setMessage("Technical error! Please contact support");
          }
          //user.setIsLoggedIn(false);
          setShowLoading(false);
        });
    }
    // validation error occured
    else {
      setMessage(validationError);
    }
  };

  return (
    <>
      <IonGrid className="registration-section" fixed={true}>
        <h1>Let's start by creating a free account</h1>
        <IonRow class="ion-padding">
          <IonCol size="12" size-lg="3"></IonCol>
          <IonCol size="12" size-lg="6">
            <IonList>
              <IonItem>
                <IonLabel position="floating">First Name</IonLabel>

                <IonInput
                  value={fname}
                  inputmode="text"
                  maxlength={30}
                  placeholder="Your first name"
                  onIonChange={(e: any) => setFname(e.target.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Last Name</IonLabel>

                <IonInput
                  value={lname}
                  inputmode="text"
                  placeholder="Your last name"
                  onIonChange={(e: any) => setLname(e.target.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>

                <IonInput
                  value={email}
                  inputmode="email"
                  placeholder="Enter your email address"
                  onIonChange={(e: any) => setEmail(e.target.value)}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  value={password}
                  inputmode="text"
                  placeholder="Make a strong password"
                  onIonChange={(e: any) => setPassword(e.target.value)}
                  type="password"
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Confirm Password</IonLabel>
                <IonInput
                  value={cfmPassword}
                  inputmode="text"
                  placeholder="Confirm your password"
                  onIonChange={(e: any) => setCfmPassword(e.target.value)}
                  type="password"
                />
              </IonItem>
              <IonItem>
                <IonSelect
                  interfaceOptions={selectInterface}
                  value={role}
                  okText="Next"
                  cancelText="Cancel"
                  onIonChange={(e) => setRole(e.detail.value)}
                >
                  <IonSelectOption value="">Select One</IonSelectOption>
                  <IonSelectOption value="3">
                    Student looking for teachers
                  </IonSelectOption>
                  <IonSelectOption value="2">
                    Educator ready to find students
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              <br />
              <IonText color="danger">
                <p className="ion-text-center text-s">{message}</p>
              </IonText>
              <br />
              <IonCheckbox
                className="registration-checkbox"
                slot="start"
                mode="md"
                checked={checked}
                onIonChange={(e) => setChecked(e.detail.checked)}
              />
              <IonText className="text-s">
                &nbsp;&nbsp;By clicking here, I verify that I am at least 18
                years of age, and I agree to the{" "}
                <Link to="/terms">Terms and Use</Link> and{" "}
                <Link to="/privacy">Privacy Policy</Link>
              </IonText>
              <br />
              <br />
              <IonRow>
                <IonCol size="12" size-lg="2"></IonCol>
                <IonCol size="12" size-lg="8">
                  <IonButton
                    fill="solid"
                    expand="block"
                    shape="round"
                    className="no-shadow"
                    color="primary"
                    type="submit"
                    onClick={registerUser}
                  >
                    <IonText>Join Now</IonText>
                  </IonButton>
                </IonCol>
                <IonCol size="12" size-lg="2"></IonCol>
              </IonRow>
            </IonList>
          </IonCol>
          <IonCol size="12" size-lg="3"></IonCol>
        </IonRow>
      </IonGrid>
      <IonModal
        isOpen={signupCompleted}
        cssClass="signup-modal"
        onDidDismiss={() => setSignupCompleted(false)}
      >
        <div className="content">
          <IonText color="success">
            <h1>
              <IonIcon icon={checkmarkCircleOutline} />
              <br />
              Congratulations!!
            </h1>
          </IonText>
          <br />
          <br />
          <span className="text-s">
            You have successfully completed the signup process. We have sent you
            an email to <IonText color="primary">{email}</IonText>
            . Kindly follow the instructions and verify your email. You can
            login with your credentials once your account is active.
          </span>
          <br />
          <br />
          <IonButton
            fill="solid"
            shape="round"
            className="no-shadow"
            color="primary"
            type="submit"
            onClick={() => {setSignupCompleted(false); window.location.href="/"}}
          >
            <IonText>Continue</IonText>
          </IonButton>
        </div>
      </IonModal>

      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
    </>
  );
};
export default Registration;
