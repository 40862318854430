/* React Import */
import {
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";

/* Utilities */
import {
  getTutorName,
  getTutorBio,
  getTutorRates,
  getProfileImage,
} from "../Utilities";

/* Application components */
import TutorProfile from "./TutorProfile";


/*****************************************************************************
 *
 * Name : ResultContainer
 * Description : Display search results to users
 * Author : Alex Jacob
 * Date Written : 28/03/2021
 *
 *****************************************************************************/

const ResultContainer: React.FC = () => {
  const count: any = useSelector<RootState>((state) => state.results.count);
  const results: any = useSelector<RootState>((state) => state.results.results);

  const showSearchResults = () => {
    if (count > 0) {
      return results.map((result: any, index: any) => (

        <IonCol size="12" size-sm="6" size-xl="4">
          <TutorProfile
            tutor_id={result.tutor_id}
            tutor_name={getTutorName(
              result.profile_summary[0].first_name,
              result.profile_summary[0].last_name
            )}
            tutor_rates={getTutorRates(
              result.pricing[0].min_hourly_rate,
              result.pricing[0].max_hourly_rate
            )}
            tutor_bio={getTutorBio(result.profile_summary[0].tutor_bio, "short")}
            tutor_img={getProfileImage(result.profile_summary[0].image)}
          />
        </IonCol>
      ));
    } else {
      return (
        <div className="search-empty-container">
          <img src="assets/images/search-empty.jpg" />
          <h1>Learn 24 hours a day, 7 days a week</h1>
          <p className="text-xs">
            Search tutors, message them and learn. It's that simple!
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <IonGrid fixed={true}>
        <IonRow>
          {showSearchResults()}
          </IonRow>
      </IonGrid>
      <br />
      <br />
    </>
  );
};

export default ResultContainer;
