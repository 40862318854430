/* React Imports */
import {
  IonLoading,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
} from "@ionic/react";
import React, { useState } from "react";


/* Import Application API */
import api from "../store/api";

/* Interface for Props */
interface ChangeProps {
  reset: boolean;
}

/*****************************************************************************
 * 
 * Name : ChangePassword 
 * Description : Password change modal window content 
 * Author : Alex Jacob
 * Date Written : 20/03/2021
 * 
 *****************************************************************************/
const ChangePassword: React.FC<ChangeProps> = ({ reset }) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [changeMessage, setChangeMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<string>("danger");
  const [showLoading, setShowLoading] = useState(false);

  const changeClick = () => {
    const authData = {
      password: newPassword,
    };

    // passwords are not blank
    if (newPassword != "" && confirmNewPassword != "") {
      // passwords are matching
      if (newPassword === confirmNewPassword) {
        setShowLoading(true);
        api
          .changePassword(authData)
          .then((result) => {
            setMessageColor("success");
            setShowLoading(false);
            setChangeMessage("Password changed successfully!");
            setNewPassword("");
            setConfirmNewPassword("");
          })
          .catch((error) => {
            setShowLoading(false);
            setNewPassword("");
            setConfirmNewPassword("");
            setChangeMessage("Oops! We couldn't change your password");
          });
      }
      // passwords are not matching
      else {
        setChangeMessage("Passwords are not matching!");
      }
    }
    // password field is blank
    else {
      setChangeMessage("Please provide a new password");
    }

    setTimeout(() => {
      setChangeMessage("");
    }, 3000);
  };


  return (
    <>
      <IonGrid fixed={true} className="ion-no-padding ion-no-margin">
        <IonRow>
          <IonCol class="ion-hide-lg-down">
            <img src="assets/images/247-login-banner.jpeg" />
          </IonCol>
          <IonCol className="ion-padding">
            <IonList>
              <br />
              <IonText class="ion-text-center">
                <h1 className="ion-no-padding ion-no-margin">
                  Change Password
                </h1>
              </IonText>
              <br />
              <br />
              <IonItem>
                <IonLabel position="floating">New Password</IonLabel>
                <IonInput
                  value={newPassword}
                  onIonChange={(e: any) => setNewPassword(e.target.value)}
                  required
                  type="password"
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Confirm New Password</IonLabel>
                <IonInput
                  value={confirmNewPassword}
                  onIonChange={(e: any) =>
                    setConfirmNewPassword(e.target.value)
                  }
                  required
                  type="password"
                />
              </IonItem>
              <br />
              <IonButton fill="outline" expand="block" onClick={changeClick}>
                <IonText color="primary">Change Password</IonText>
              </IonButton>
              <IonText color={messageColor}>
                <p className="ion-text-center text-s">{changeMessage}</p>
              </IonText>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={2000}
      />
    </>
  );
};
export default ChangePassword;
