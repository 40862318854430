export const SIGNIN = 'SIGNIN';
export const SEARCH = 'SEARCH';
export const CLEARSEARCH = 'CLEARSEARCH';
export const STOREACTION = 'STOREACTION';
export const SVCLST = 'SVCLST';
export const STATES = 'STATES';
export const CITIES = 'CITIES';
export const LEVELS = 'LEVELS';
export const SUBJECTS = 'SUBJECTS';
export const TIMEZONES = 'TIMEZONES';
export const SCHEDULE = 'SCHEDULE';
export const SELECTEDTUTOR = 'SELECTEDTUTOR';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UPDATEPAYMENT = 'UPDATEPAYMENT';
export const GETSUBSCRIPTION = 'GETSUBSCRIPTION';
export const CANCELSUBSCRIPTION = 'CANCELSUBSCRIPTION';
export const GETCHATHEADS = 'GETCHATHEADS';
export const SELECTCHAT = 'SELECTCHAT';

/* Tutor Service Actions */

export const STORE_TUTOR_PROFILE = 'STORE_TUTOR_PROFILE';
