/* React Imports */
import { IonCol, IonGrid, IonRow, IonProgressBar} from "@ionic/react";
import React, { useState } from "react";
import { RootState } from "../store/reducers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

/* Redux store actions */
import {
  getLevels,
  getCities,
  clearSearchResults,
  storeSearchResults,
  getAvailableSubjects,
} from "../store/actions";

/* Application APIs*/
import api from "../store/api";

/* Application components */
import SearchStatusBar from "../components/SearchStatusBar";
import DummyProfile from "../components/DummyProfile";

/*****************************************************************************
 *
 * Name : SearchContainer
 * Description : Search container with all core search logic
 * Author : Alex Jacob
 * Date Written : 22/03/2021
 *
 *****************************************************************************/

const SearchContainer: React.FC = () => {
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [selectedService, setSelectedService] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedLevel, setSelectedLevel] = useState<string>("");
  const [selectedSubject, setSelectedSubject] = useState<string>("");

  const [showLoading, setShowLoading] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");

  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const services: any = useSelector<RootState>(
    (state) => state.services.services
  );
  const states: any = useSelector<RootState>((state) => state.location.states);
  const cities: any = useSelector<RootState>((state) => state.location.cities);
  const levels: any = useSelector<RootState>((state) => state.services.levels);
  const subjects: any = useSelector<RootState>(
    (state) => state.services.subjects
  );


  const searchData = {
    service_id: selectedService,
    level_id: selectedLevel,
    subject_id: selectedSubject,
    state_id: selectedState,
    city_id: selectedCity,
    modality: selectedMode
  };

  /* Handle the service dropdown box */
  const handleModality = (mode: string) => {
    setSelectedMode(mode);
    /* enable the city and state for only in person services */
    if (mode === "2" || mode === "3") {
      setDisabled(false);
    } else {
      setSelectedState("");
      setSelectedCity("");
      setDisabled(true);
    }
  };

  /* Handle the state dropdown box */
  const handleState = (id: string) => {
    setSelectedCity("");
    setSelectedState(id);
    if (id) {
      /* get the name of all cities based on the selected state */
      dispatch(getCities(id));
    }
  };

  /* Handle city dropdown box */
  const handleCity = (cityid: string) => {
    setSelectedCity(cityid);
  };

   /* Handle the service dropdown box */
   const handleService = (service: string) => {
    setSelectedSubject("");
    setSelectedLevel("");
    setSelectedService(service);
    if(service)
    {
      dispatch(getLevels(service))
    }
  };

  /* Handle level doropdown box */
  const handleLevel = (levelid: string) => {
    setSelectedSubject("");
    setSelectedLevel(levelid);
    if (levelid) {
      dispatch(getAvailableSubjects(JSON.stringify({ levels: [levelid] })));
    }
  };

  /* Handle subject dropdown box */
  const handleSubject = (subjectid: string) => {
    setSelectedSubject(subjectid);
  };

  /**************************************************************************
   *  HANDLE SEARCH LOGIC HERE
   ***************************************************************************/
  const handleSearch = () => {
    
    let validSearch = false;
    dispatch(clearSearchResults());

    if(selectedService === "")
    {
      setSearchStatus("That's too broad! Please select any service");
    }
    else
    {
      validSearch = true;
    }

    
    if (validSearch) {
      setShowLoading(true);
      setSearchStatus("Searching...");
      api
        .tutorSearch(searchData)
        .then((result) => {
          setShowLoading(false);
          if (result.data.reponseData.count > 0) {
            result.data.reponseData.count === 1
              ? setSearchStatus(
                  "We found " +
                    result.data.reponseData.count +
                    " tutor for you!"
                )
              : setSearchStatus(
                  "We found " +
                    result.data.reponseData.count +
                    " tutors for you!"
                );
          } else {
            setSearchStatus(
              "Oops! No match found for this. try something else?"
            );
          }
          if (result.data.reponseData.records != null)
            dispatch(storeSearchResults(result.data.reponseData));
        })
        .catch((error) => {
          setShowLoading(false);
          console.log("TutorSearch Failed");
        });
    }
    
  };

  return (
    <>
      <div className="search-container">
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol>
              <h1>Find Your Tutor </h1>
            </IonCol>
          </IonRow>
          <br />
          <IonRow>
          <IonCol size="12" size-xl="2">
              <select
                className="form-control"
                name="modality"
                id="modality"
                value={selectedMode}
                onChange={(e: any) => handleModality(e.target.value)}
              >
                <option value="0">Select Mode</option>
                <option value="1">Online</option>
                <option value="2">In Person</option>
                <option value="3">Online & In Person</option>
              </select>
            </IonCol>
            <IonCol size="12" size-xl="2">
              <select
                className="form-control"
                name="state"
                id="state"
                value={selectedState}
                onChange={(e: any) => handleState(e.target.value)}
                disabled={disabled}
              >
                <option value="">Select State</option>
                {states.map((stateRow: any, index: any) => (
                  <option value={stateRow.state_id}>
                    {stateRow.state_name}
                  </option>
                ))}
              </select>
            </IonCol>
            <IonCol size="12" size-xl="2">
              <select
                className="form-control"
                name="city"
                id="city"
                value={selectedCity}
                onChange={(e: any) => handleCity(e.target.value)}
                disabled={disabled}
              >
                <option value="">Select City</option>
                {cities.map((cities: any, index: any) => (
                  <option value={cities.city_id}>{cities.city_name}</option>
                ))}
              </select>
            </IonCol>

            <IonCol size="12" size-xl="2">
              <select
                className="form-control"
                name="service"
                id="service"
                value={selectedService}
                onChange={(e: any) => handleService(e.target.value)}
              >
                <option value="">Select Service</option>
                {services.map((service: any, index: any) => (
                  <option value={service.svc_id}>{service.svc_name}</option>
                ))}
              </select>
            </IonCol>
            <IonCol size="12" size-xl="2">
              <select
                className="form-control"
                name="level"
                id="level"
                value={selectedLevel}
                onChange={(e: any) => handleLevel(e.target.value)}
              >
                <option value="">Select Level</option>
                {selectedService != "" ? levels.map((level: any, index: any) => (
                  <option value={level.level_id}>{level.level_name}</option>
                )): null}
              </select>
            </IonCol>
            <IonCol size="12" size-xl="2">
              <select
                className="form-control"
                name="subject"
                id="subject"
                value={selectedSubject}
                onChange={(e: any) => handleSubject(e.target.value)}
              >
                <option value="">Select Subject</option>
                {selectedLevel != "" ? subjects.map((subject: any, index: any) => (
                  <option value={subject.sub_id}>{subject.sub_name}</option>
                )): null}
              </select>
            </IonCol>
            <IonCol>
              <div className="search-button" onClick={handleSearch}>
                Search Tutors
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <SearchStatusBar status={searchStatus} />
      {showLoading === true ? (
        <IonProgressBar type="indeterminate"></IonProgressBar>
      ) : null}
      {showLoading === true ? <DummyProfile /> : null}
    </>
  );
};

export default SearchContainer;
