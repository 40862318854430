import * as actions from "../actions/actions";

const initialState = {
  details: null
};

const storeSubscription = (state: any, action: any) => {
  return {
    ...state,
    details: action.data
  };
};

const storeSubscriptionDetails = (state: any, action: any) => {
  return {
    ...state,
    details: action.data
  };
};

const clearSubscriptionDetails = (state: any, action: any) => {
  return {
    ...state,
    details: action.data
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
      case actions.SUBSCRIBE:
      return storeSubscription(state, action);
      case actions.GETSUBSCRIPTION:
      return storeSubscriptionDetails(state, action);
      case actions.CANCELSUBSCRIPTION:
      return clearSubscriptionDetails(state, action);
    default:
      return state;
  }
};

export default reducer;
