import * as actions from "../actions/actions";

const initialState = {
  services: [],
  levels: [],
  subjects: [],
  results: [],
};

const storeServiceList = (state: any, action: any) => {
  return {
    ...state,
    services: action.data,
  };
};

const storeLevels = (state: any, action: any) => {
  return {
    ...state,
    levels: action.data,
  };
};

const storeSubjects = (state: any, action: any) => {
  return {
    ...state,
    subjects: action.data,
  };
};
const storeSchedule = (state: any, action: any) => {
  return {
    ...state,
    schedule: action.data,
  };
};

const storeResults = (state: any, action: any) => {
  return {
    ...state,
    results: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.SVCLST:
      return storeServiceList(state, action);
    case actions.LEVELS:
      return storeLevels(state, action);
    case actions.SUBJECTS:
      return storeSubjects(state, action);
      case actions.SCHEDULE:
      return storeSchedule(state, action);
    case actions.SEARCH:
      return storeResults(state, action);
    default:
      return state;
  }
};

export default reducer;
